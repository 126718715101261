import {
  Menu,
  MenuItem,
  Button,
  TextField
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PractitionerNotification from "../SubComponent/PractitionerNotification";
import { Logout, resetLogoutState } from "../../Actions/Auth/LogoutAction";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const PractitionerMainHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserData = JSON.parse(localStorage.getItem("user"));
  const localDeviceId = JSON.parse(localStorage.getItem("deviceId"));
  const [deviceId, setDeviceId] = useState(localDeviceId ? localDeviceId : null);
  const [anchorEl, setAnchorEl] = useState(null);
  const logout = useSelector((state) => state.logout.logout);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const NavigateToHome = () => {
    localStorage.removeItem('user');
    localStorage.clear();
    navigate('/practitioner-landing-page');
  }
  const getDeviceID = () => {
    if (navigator && navigator.userAgent) {
      return navigator.userAgent;
    } else {
      return "default_device_id";
    }
  };
  useEffect(() => {
    if (logout && logout !== "isLoading" && logout.length !== 0 && logout.status === true) {
      NavigateToHome();
      dispatch(resetLogoutState());
    }
  }, [logout]);
  useEffect(() => {
    const id = getDeviceID();
    setDeviceId(id);
  }, []);
  const handleLogout = () => {
    if (deviceId) {
      const userId = UserData.user.id;
      dispatch(Logout(deviceId, userId));
    }
  };

  const handleLogoClick = () => {
    navigate('/practitioner-landing-page');
  }
  return (

    <header className="self-stretch shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] bg-neutral-1 box-border flex flex-row items-start justify-between py-[22px] pr-[65px] pl-[63px] top-[0] z-[50] sticky max-w-full gap-[20px] border-[1px] border-solid border-gainsboro-100 mq750:pl-[31px] mq750:pr-8 mq750:box-border">
      <div className="w-[251.8px] flex flex-col items-start justify-start pt-[5.5px] px-0 pb-0 box-border cursor-pointer" onClick={handleLogoClick}>
        <div className="self-stretch flex flex-row items-end justify-start gap-[13.2px]">
          <img
            className="h-[50px] w-[50.6px] relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/the-healers-logo.svg"
          />
          <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[13px]">
            <img
              className="self-stretch h-[23.9px] relative max-w-full overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/the-healers.svg"
            />
          </div>
        </div>
      </div>
      {UserData && UserData.user && (
        <div className="max-w-200 overflow-hidden shrink-0 flex flex-row items-center justify-between box-border gap-[0px] ml-10">
        <PractitionerNotification />
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >

          {UserData.user.profileImage ? (
            <img
              className="ml-2"
              width="41px"
              height="41px"
              src={UserData.user.profileImage}
              alt="Avatar"
            />
          ) : (
            <img
              className="ml-2"
              width="41px"
              height="41px"
              src="/Rectangle 39977.svg"
              alt="Avatar"
            />
          )}
          <span
            style={{ textTransform: "none" }}
            className="ml-2 text-base text-black font-inherit"
          >
            {UserData.user.fullName}
          </span>
          <img
            className="ml-2"
            width="24px"
            height="24px"
            src="/keyboard-arrow-down.svg"
            alt="Dropdown"
          />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem disabled>
            My account
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
      )}
    </header>
  );
};

export default PractitionerMainHeader;
