import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppointmentCardPending from "../SubComponent/AppointmentCardPending";
import { useDispatch, useSelector } from "react-redux";
import { getPendingBooking, getHistoryBooking, getUpcomingBooking } from "../../Actions/Practitioner/GetBookingAction";
import Loader from "../SubComponent/Loader";
import AppointmentCardHistory from "../SubComponent/AppointmentCardHistory";
import AppointmentCardUpcoming from "../SubComponent/AppointmentCardUpcoming";
import Menu from '@mui/material/Menu';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FilterButton from "../SubComponent/FilterButton";
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ResetFilterButton from "../SubComponent/PractitionerResetFilterButton";
import { Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import dayjs from "dayjs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AppointmentsHeader = (services) => {
  const UserData = JSON.parse(localStorage.getItem("user"));
  const PractitionerId = UserData.user.id;
  const dispatch = useDispatch();
  const cardsPerPage = 10;
  const practitionerServices = services.services;

  const [updateCount, setUpdateCount] = useState(0);
  const [selectedUpcomingDateRange, setSelectedUpcomingDateRange] = useState("");
  const [selectedUpcomingServices, setSelectedUpcomingServices] = useState([]);
  const [customUpcomingDateRange, setCustomUpcomingDateRange] = useState([null, null]);
  const [showUpcomingDateRangePicker, setShowUpcomingDateRangePicker] = useState(false);
  const [fromDateUpcoming, setFromDateUpcoming] = useState(null);
  const [toDateUpcoming, setToDateUpcoming] = useState(null);
  const [fromDatePending, setFromDatePending] = useState(null);
  const [toDatePending, setToDatePending] = useState(null);
  const [fromDateHistory, setFromDateHistory] = useState(null);
  const [toDateHistory, setToDateHistory] = useState(null);
  const [currentPageUpcoming, setCurrentPageUpcoming] = useState(1);
  const [totalPagesUpcoming, setTotalPagesUpcoming] = useState(1);
  const [selectedPendingDateRange, setSelectedPendingDateRange] = useState("");
  const [selectedHistoryDateRange, setSelectedHistoryDateRange] = useState("");

  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [selectedPendingServices, setSelectedPendingServices] = useState([]);
  const [selectedHistoryServices, setSelectedHistoryServices] = useState([]);
  
  const [customPendingDateRange, setCustomPendingDateRange] = useState([null, null]);
  const [customHistoryDateRange, setCustomHistoryDateRange] = useState([null, null]);
 
  const [showPendingDateRangePicker, setShowPendingDateRangePicker] = useState(false);
  const [showHistoryDateRangePicker, setShowHistoryDateRangePicker] = useState(false);

  const dateRangePickerRef = useRef(null);
  
  const [currentPagePending, setCurrentPagePending] = useState(1);
  const [currentPageHistory, setCurrentPageHistory] = useState(1);
 
  const [totalPagesPending, setTotalPagesPending] = useState(1);
  const [totalPagesHistory, setTotalPagesHistory] = useState(1);
 const upcomingBookings = useSelector((state) => state.getBooking.getUpcomingBooking);
  const historyBookings = useSelector((state) => state.getBooking.getHistoryBooking);
  const pendingBookings = useSelector((state) => state.getBooking.getBooking)
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);

  };

  const handlePageChange = (newPage) => {
    switch (tabIndex) {
      case 0:
        if (newPage > 0 && newPage <= totalPagesUpcoming) {
          setCurrentPageUpcoming(newPage);
        }
        break;
      case 1:
        if (newPage > 0 && newPage <= totalPagesPending) {
          setCurrentPagePending(newPage);
        }
        break;
      case 2:
        if (newPage > 0 && newPage <= totalPagesHistory) {
          setCurrentPageHistory(newPage);
        }
        break;
      default:
        break;
    }
   
  };
  
useEffect(() => {
    if (tabIndex === 0) {
      dispatch(getUpcomingBooking(
        PractitionerId,
        currentPageUpcoming,
        fromDateUpcoming,
        toDateUpcoming,
        selectedUpcomingServices
      ));
    }
    else if (tabIndex === 1) {
      dispatch(getPendingBooking(PractitionerId, currentPagePending,fromDatePending,toDatePending,selectedPendingServices,selectedDuration,
        selectedServiceType));
    }
    else if (tabIndex === 2) {
      dispatch(getHistoryBooking(PractitionerId, currentPageHistory,fromDateHistory,toDateHistory,selectedHistoryServices,selectedStatus));
    }




  },  [dispatch, PractitionerId, updateCount, tabIndex, currentPageUpcoming,selectedStatus,currentPagePending, fromDateUpcoming, toDateUpcoming, selectedUpcomingServices,fromDatePending,toDatePending,fromDateHistory,toDateHistory,selectedHistoryServices,selectedDuration,selectedServiceType,selectedPendingServices, currentPageHistory]);

 

  useEffect(() => {
    if (upcomingBookings && upcomingBookings.totalCount) {

      setTotalPagesUpcoming(Math.ceil(upcomingBookings.totalCount / cardsPerPage));
    }
    if (pendingBookings && pendingBookings.totalCount) {

      setTotalPagesPending(Math.ceil(pendingBookings.totalCount / cardsPerPage));
    }
    if (historyBookings && historyBookings.totalCount) {

      setTotalPagesHistory(Math.ceil(historyBookings.totalCount / cardsPerPage));
    }
  }, [upcomingBookings, pendingBookings, historyBookings]);


  const handleBookingUpdate = () => {

    setUpdateCount((prev) => prev + 1);
  };
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = useState(null);
  const [durationAnchorEl, setDurationAnchorEl] = useState(null);
  const [serviceTypeAnchorEl, setServiceTypeAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);

 
  const handleCustomUpcomingDateRangeChange = (newValue) => {
    setCustomUpcomingDateRange(newValue);
    if (newValue[0] && newValue[1]) {
        setFromDateUpcoming(dayjs(newValue[0]).toISOString());
        setToDateUpcoming(dayjs(newValue[1]).toISOString());
        setShowUpcomingDateRangePicker(false);
        setDateRangeAnchorEl(null);
    }
};

  const handleOpen = (setter) => (event) => {
    setter(event.currentTarget);
  };

  const handleClose = (setter) => () => {
    setter(null);
  };
  const handleClickOutside = (event) => {
    if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
      setShowUpcomingDateRangePicker(false);
      setShowPendingDateRangePicker(false);
      setShowHistoryDateRangePicker(false);
    }
  };
  const handleUpcomingServiceChange = (event) => {
    const value = event.target.value;
    setSelectedUpcomingServices(prev =>
        prev.includes(value) ? prev.filter(s => s !== value) : [...prev, value]
    );
};


  const resetUpcomingBookingsFilter = () => {
    setSelectedUpcomingDateRange("");
    setSelectedUpcomingServices([]);
    setCustomUpcomingDateRange([null, null]);
    setFromDateUpcoming(null);
    setToDateUpcoming(null);
  };

  const handleUpcomingDateRangeChange = (event) => {
    const value = event.target.value;
    setSelectedUpcomingDateRange(value);
    if (value === "Custom") {
      setShowUpcomingDateRangePicker(true);
    } else {
      setShowUpcomingDateRangePicker(false);
      setDateRangeBasedOnSelection(value);
    }}
  const setDateRangeBasedOnSelection = (range) => {
    const now = dayjs();
    let startDate, endDate;

    switch (range) {
      case "Today":
        startDate = now.startOf('day');
        endDate = now.endOf('day');
        break;
      case "This week":
        startDate = now.startOf('week');
        endDate = now.endOf('week');
        break;
      case "This month":
        startDate = now.startOf('month');
        endDate = now.endOf('month');
        break;
      default:
        startDate = endDate = null;
    }

    setFromDateUpcoming(startDate ? startDate.toISOString() : null);
    setToDateUpcoming(endDate ? endDate.toISOString() : null);
  };


  const handleCustomPendingDateRangeChange = (newValue) => {
    setCustomPendingDateRange(newValue);
    if (newValue[0] && newValue[1]) {
        setFromDatePending(dayjs(newValue[0]).toISOString());
        setToDatePending(dayjs(newValue[1]).toISOString());
        setShowPendingDateRangePicker(false);
        setDateRangeAnchorEl(null);
    }
};
console.log(selectedUpcomingServices,'services')
 
  const setDateRangeBasedOnSelection1 = (range) => {
    const now = dayjs();
    let startDate, endDate;
  
    switch (range) {
      case "Today":
        startDate = now.startOf('day');
        endDate = now.endOf('day');
        break;
      case "This week":
        startDate = now.startOf('week');
        endDate = now.endOf('week');
        break;
      case "This month":
        startDate = now.startOf('month');
        endDate = now.endOf('month');
        break;
      default:
        startDate = endDate = null;
    }
  
    setFromDatePending(startDate ? startDate.toISOString() : null);
    setToDatePending(endDate ? endDate.toISOString() : null);
  };
  
  const handleDurationChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedDuration(value
    );
  };

  const handleServiceTypeChange = (event) => {
    const value = parseInt(event.target.value, 10);
   
    setSelectedServiceType(value
    );
  };

  const handlePendingServiceChange = (event) => {
    const value = event.target.value;


    setSelectedPendingServices((prev) =>
      prev.includes(value) ? prev.filter((s) => s !== value) : [...prev, value]
    );
  };
 
  const resetPendingBookingsFilter = () => {
    setSelectedPendingDateRange("");
    setSelectedPendingServices([]);
    setSelectedDuration(null);
    setSelectedServiceType(null);
    setCustomPendingDateRange([null, null]);
  };
  const handlePendingDateRangeChange = (event) => {
    const value = event.target.value;
    setSelectedPendingDateRange(value);
    if (value === "Custom") {
      setShowPendingDateRangePicker(true);
    } else {
      setShowPendingDateRangePicker(false);
      setDateRangeBasedOnSelection1(value)
    };
  };

 
  const handleCustomHistoryDateRangeChange = (newValue) => {
    setCustomHistoryDateRange(newValue);
    if (newValue[0] && newValue[1]) {
      setFromDateHistory(dayjs(newValue[0]).toISOString());
      setToDateHistory(dayjs(newValue[1]).toISOString());
      setShowHistoryDateRangePicker(false);
      setDateRangeAnchorEl(null);
    }
  };
 
 const setDateRangeBasedOnSelection2 = (range) => {
    const now = dayjs();
    let startDate, endDate;
  
    switch (range) {
      case "Today":
        startDate = now.startOf('day');
        endDate = now.endOf('day');
        break;
      case "This week":
        startDate = now.startOf('week');
        endDate = now.endOf('week');
        break;
      case "This month":
        startDate = now.startOf('month');
        endDate = now.endOf('month');
        break;
      default:
        startDate = endDate = null;
    }
  
    setFromDateHistory(startDate ? startDate.toISOString() : null);
    setToDateHistory(endDate ? endDate.toISOString() : null);
  };
  const handleHistoryDateRangeChange = (event) => {
    const value = event.target.value;
    setSelectedHistoryDateRange(value);
    if (value === "Custom") {
      setShowHistoryDateRangePicker(true);
    } else {
      setShowHistoryDateRangePicker(false);
      setDateRangeBasedOnSelection2(value)
    }
  };
  const handleStatusChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedStatus(value);
  };
  const handleHistoryServiceChange = (event) => {
    const value = event.target.value;
    setSelectedHistoryServices((prev) =>
      prev.includes(value) ? prev.filter((s) => s !== value) : [...prev, value]
    );
  };

  
  const resetHistoryBookingsFilter = () => {
    setSelectedHistoryDateRange("");  
    setCustomHistoryDateRange([null, null]); 
    setSelectedHistoryServices([]);  
      setFromDateHistory(null);
      setToDateHistory(null);
    setSelectedStatus(null);
   
  };
 
  useEffect(() => {
    const hideLicenseKeyMessage = () => {
      const elements = document.querySelectorAll('div[style*="color: rgba(130, 130, 130, 0.62)"]');
      elements.forEach(element => {
        if (element.textContent.includes("MUI X Missing license key")) {
          element.style.opacity = "0";
        }
      });
    };

    hideLicenseKeyMessage();

    const observer = new MutationObserver(hideLicenseKeyMessage);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);


  useEffect(() => {
    if (showUpcomingDateRangePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else if (showPendingDateRangePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else if (showHistoryDateRangePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showUpcomingDateRangePicker, showPendingDateRangePicker, showHistoryDateRangePicker]);




  return (
    <div className={`items-start flex-row justify-start gap-[56px] w-full mq750:gap-[28px] `}>
      <div className="w-[444px] flex flex-col items-start justify-start gap-[8px] max-w-full text-13xl">
        <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
          Appointments
        </h1>
        <div className="self-stretch relative text-sm leading-[22px]">
          View your upcoming, pending, and history appointments
        </div>
      </div>
      <div className="flex flex-row items-center">
        <div className="flex-2">
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="appointment tabs">
            <Tab label="Upcoming" />
            <Tab label="Pending" />
            <Tab label="History" />
          </Tabs>
        </div>
        <div className="flex-1 flex justify-end gap-[8px]">
          {tabIndex === 0 && (
            <>
              <div>
                <ResetFilterButton onClick={resetUpcomingBookingsFilter} />
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setDateRangeAnchorEl)} open={Boolean(dateRangeAnchorEl)} icon={<CalendarMonthOutlinedIcon />} text="Date Range"
                />
                <Menu
                  id="dateRange-menu"
                  anchorEl={dateRangeAnchorEl}
                  open={Boolean(dateRangeAnchorEl)}
                  onClose={handleClose(setDateRangeAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedUpcomingDateRange}
                    onChange={handleUpcomingDateRangeChange}
                    style={{ padding: "6px 16px 6px 16px " }}
                  >
                    <FormControlLabel value="Today" control={<Radio />} label="Today" />
                    <FormControlLabel value="This week" control={<Radio />} label="This week" />
                    <FormControlLabel value="This month" control={<Radio />} label="This month" />
                    <Divider />
                    <FormControlLabel
                      value="Custom"
                      control={<Radio style={{ display: 'none' }} />}
                      label={
                        <Box
                          ml={1.3}
                          display="flex"
                          alignItems="center"
                          mt={1}
                        >
                          <AddIcon sx={{ color: 'grey.600' }} />
                          <Box component="span" ml={1}>Custom date</Box>
                        </Box>
                      }
                      onClick={() => {
                        handleCustomUpcomingDateRangeChange({ target: { value: 'Custom' } });
                      }}
                    />
                  </RadioGroup>
                </Menu>
                {showUpcomingDateRangePicker && (
                  <Box
                    ref={dateRangePickerRef}
                    sx={{
                      position: 'absolute',
                      top: '56%',
                      left: '61%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1400,
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      p: 4,
                    }}
                  >

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangeCalendar
                        disableError={true}
                        value={customUpcomingDateRange}
                        onChange={handleCustomUpcomingDateRangeChange}
                      />
                    </LocalizationProvider>
                  </Box>
                )}
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setServicesAnchorEl)} open={Boolean(servicesAnchorEl)} icon={<SpaOutlinedIcon />} text="Services"
                />
                <Menu
                  id="services-menu"
                  anchorEl={servicesAnchorEl}
                  open={Boolean(servicesAnchorEl)}
                  onClose={handleClose(setServicesAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px" }}>
                  {practitionerServices && practitionerServices.length > 0 && practitionerServices.map((service) => (
                                    <FormControlLabel
                                        key={service.id}
                                        control={
                                            <Checkbox
                                                checked={selectedUpcomingServices.includes(service.id)}
                                                onChange={handleUpcomingServiceChange}
                                                value={service.id}
                                            />
                                        }
                                       
                        label={service.name}
                      />
                    ))}
                  </FormGroup>
                </Menu>
              </div>
            </>
          )}
 
          {tabIndex === 1 && (
            <>
              <div>
                <ResetFilterButton onClick={resetPendingBookingsFilter} />
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setDateRangeAnchorEl)} open={Boolean(dateRangeAnchorEl)} icon={<CalendarMonthOutlinedIcon />} text="Date Range"
                />
                <Menu
                  id="dateRange-menu"
                  anchorEl={dateRangeAnchorEl}
                  open={Boolean(dateRangeAnchorEl)}
                  onClose={handleClose(setDateRangeAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedPendingDateRange}
                    onChange={handlePendingDateRangeChange}
                    style={{ padding: "6px 16px 6px 16px " }}
                  >
                    <FormControlLabel value="Today" control={<Radio />} label="Today" />
                    <FormControlLabel value="This week" control={<Radio />} label="This week" />
                    <FormControlLabel value="This month" control={<Radio />} label="This month" />
                    <Divider />
                    <FormControlLabel
                      value="Custom"
                      control={<Radio style={{ display: 'none' }} />}
                      label={
                        <Box
                          ml={1.3}
                          display="flex"
                          alignItems="center"
                          mt={1}
                        >
                          <AddIcon sx={{ color: 'grey.600' }} />
                          <Box component="span" ml={1}>Custom date</Box>
                        </Box>
                      }
                      onClick={() => handlePendingDateRangeChange({ target: { value: 'Custom' } })}
                    />
                  </RadioGroup>
                </Menu>
                {showPendingDateRangePicker && (
                  <Box
                    ref={dateRangePickerRef}
                    sx={{
                      position: 'absolute',
                      top: '56%',
                      left: '61%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1400,
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangeCalendar
                        disableError={true}
                        value={customPendingDateRange}
                        onChange={handleCustomPendingDateRangeChange}
                      />
                    </LocalizationProvider>
                  </Box>
                )}
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setDurationAnchorEl)} open={Boolean(durationAnchorEl)} icon={<TimelapseOutlinedIcon />} text="Duration"
                />
                <Menu
                  id="duration-menu"
                  anchorEl={durationAnchorEl}
                  open={Boolean(durationAnchorEl)}
                  onClose={handleClose(setDurationAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px " }}>
                    <FormControlLabel control={<Checkbox
                      checked={selectedDuration===30}
                      onChange={handleDurationChange}
                      value={30}
                    />} label="30 min" />
                    <FormControlLabel control={<Checkbox
                      checked={selectedDuration===60}
                      onChange={handleDurationChange}
                      value={60}
                    />} label="60 min" />
                    <FormControlLabel control={<Checkbox
                      checked={selectedDuration===90}
                      onChange={handleDurationChange}
                      value={90}
                    />} label="90 min" />
                    <FormControlLabel control={<Checkbox
                      checked={selectedDuration===120}
                      onChange={handleDurationChange}
                      value={120}
                    />} label="120 min" />
                  </FormGroup>

                </Menu>
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setServiceTypeAnchorEl)} open={Boolean(serviceTypeAnchorEl)} icon={<CategoryOutlinedIcon />} text="Service Type"
                />
                <Menu
                  id="serviceType-menu"
                  anchorEl={serviceTypeAnchorEl}
                  open={Boolean(serviceTypeAnchorEl)}
                  onClose={handleClose(setServiceTypeAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px " }}>
                    <FormControlLabel control={<Checkbox
                      checked={selectedServiceType===0}
                      onChange={handleServiceTypeChange}
                      value={0}
                    />} label="Online" />
                    <FormControlLabel control={<Checkbox
                      checked={selectedServiceType===1}
                      onChange={handleServiceTypeChange}
                      value={1}
                    />} label="On site" />
                  </FormGroup>
                </Menu>
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setServicesAnchorEl)} open={Boolean(servicesAnchorEl)} icon={<SpaOutlinedIcon />} text="Services"
                />
                <Menu
                  id="services-menu"
                  anchorEl={servicesAnchorEl}
                  open={Boolean(servicesAnchorEl)}
                  onClose={handleClose(setServicesAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px" }}>
                    {practitionerServices && practitionerServices !== null && practitionerServices.length !== 0 && practitionerServices.map((service) => (
                      <FormControlLabel
                        key={service.id}
                        control={
                          <Checkbox
                            checked={selectedPendingServices.includes(service.id)}
                            onChange={handlePendingServiceChange}
                            value={service.id}
                          />
                        }
                        label={service.name}
                      />
                    ))}
                  </FormGroup>

                </Menu>
              </div>
            </>
          )}

          {tabIndex === 2 && (
            <>
              <div>
                <ResetFilterButton onClick={resetHistoryBookingsFilter} />
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setDateRangeAnchorEl)} open={Boolean(dateRangeAnchorEl)} icon={<CalendarMonthOutlinedIcon />} text="Date Range"
                />
                <Menu
                  id="dateRange-menu"
                  anchorEl={dateRangeAnchorEl}
                  open={Boolean(dateRangeAnchorEl)}
                  onClose={handleClose(setDateRangeAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedHistoryDateRange}
                    onChange={handleHistoryDateRangeChange}
                    style={{ padding: "6px 16px 6px 16px " }}
                  >
                    <FormControlLabel value="Today" control={<Radio />} label="Today" />
                    <FormControlLabel value="This week" control={<Radio />} label="This week" />
                    <FormControlLabel value="This month" control={<Radio />} label="This month" />
                    <Divider />
                    <FormControlLabel
                      value="Custom"
                      control={<Radio style={{ display: 'none' }} />}
                      label={
                        <Box
                          ml={1.3}
                          display="flex"
                          alignItems="center"
                          mt={1}
                        >
                          <AddIcon sx={{ color: 'grey.600' }} />
                          <Box component="span" ml={1}>Custom date</Box>
                        </Box>
                      }
                      onClick={() => handleHistoryDateRangeChange({ target: { value: 'Custom' } })}
                    />
                  </RadioGroup>
                </Menu>
                {showHistoryDateRangePicker && (
                  <Box
                    ref={dateRangePickerRef}
                    sx={{
                      position: 'absolute',
                      top: '56%',
                      left: '61%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1400,
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      p: 4,
                    }}
                  >

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangeCalendar
                        disableError={true}
                        value={customHistoryDateRange}
                        onChange={handleCustomHistoryDateRangeChange}
                      />
                    </LocalizationProvider>
                  </Box>
                )}
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setStatusAnchorEl)} open={Boolean(statusAnchorEl)} icon={<CheckCircleOutlineOutlinedIcon />} text="Status"
                />
                <Menu
                  id="status-menu"
                  anchorEl={statusAnchorEl}
                  open={Boolean(statusAnchorEl)}
                  onClose={handleClose(setStatusAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px " }}>
                    <FormControlLabel control={<Checkbox
                      checked={selectedStatus===7}
                      onChange={handleStatusChange}
                      value={7}
                    />} label="Completed" />
                    <FormControlLabel control={<Checkbox
                      checked={selectedStatus===5}
                      onChange={handleStatusChange}
                      value={5}
                    />} label="Cancelled" />
                  </FormGroup>

                </Menu>
              </div>
              <div>
                <FilterButton
                  onClick={handleOpen(setServicesAnchorEl)} open={Boolean(servicesAnchorEl)} icon={<SpaOutlinedIcon />} text="Services"
                />
                <Menu
                  id="services-menu"
                  anchorEl={servicesAnchorEl}
                  open={Boolean(servicesAnchorEl)}
                  onClose={handleClose(setServicesAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <FormGroup style={{ padding: "6px 16px 6px 16px" }}>
                    {practitionerServices && practitionerServices !== null && practitionerServices.length !== 0 && practitionerServices.map((service) => (
                      <FormControlLabel
                        key={service.id}
                        control={
                          <Checkbox
                            checked={selectedHistoryServices.includes(service.id)}
                            onChange={handleHistoryServiceChange}
                            value={service.id}
                          />
                        }
                        label={service.name}
                      />
                    ))}
                  </FormGroup>

                </Menu>
              </div>
            </>
          )}

        </div>
      </div>
   
  <TabPanel value={tabIndex} index={0}>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 pr-0 pl-0 mt-5 box-border gap-[22px] max-w-full text-neutral-9">
              {upcomingBookings === "isLoading" && <Loader />}
              {upcomingBookings && upcomingBookings !== "isLoading" && upcomingBookings.result.length > 0 &&
                upcomingBookings.result.map((booking) => (
                  <AppointmentCardUpcoming
                    key={booking.id}
                    booking={booking}
                    onBookingUpdate={handleBookingUpdate}
                  />
                ))
              }
              {upcomingBookings && upcomingBookings !== "isLoading" && upcomingBookings.result.length === 0 && <div>No bookings available</div>}
            </div>
            <div className="flex flex-row items-center justify-center gap-[20px] mt-4">
              <Button
                className="bg-pract_green"
                variant="contained"
                disabled={currentPageUpcoming === 1}
                onClick={() => handlePageChange(currentPageUpcoming - 1)}
              >
                Previous
              </Button>
              <Typography variant="body1">
                Page {currentPageUpcoming} of {totalPagesUpcoming}
              </Typography>
              <Button
                className="bg-pract_green"
                variant="contained"
                disabled={currentPageUpcoming === totalPagesUpcoming}
                onClick={() => handlePageChange(currentPageUpcoming + 1)}
              >
                Next
              </Button>
            </div>
          </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <div className="self-stretch flex flex-row flex-wrap mt-5 items-start justify-start py-0 pl-0 box-border gap-[22px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border">
          {pendingBookings === "isLoading" && <div><Loader></Loader></div>}

        
          {pendingBookings && pendingBookings !== "isLoading" && pendingBookings.result && pendingBookings.result.length > 0 &&
                pendingBookings.result.map((booking) => (
                  <AppointmentCardPending
                    key={booking.id}
                    booking={booking}
                    onBookingUpdate={handleBookingUpdate}
                  />
                ))
              }
              {pendingBookings && pendingBookings !== "isLoading" &&pendingBookings.result &&  pendingBookings.result.length === 0 && <div>No bookings available</div>}
    
        </div>
        <div className="flex flex-row items-center justify-center gap-[20px] mt-4">
          <Button
            className="bg-pract_green"
            variant="contained"
            disabled={currentPagePending === 1}
            onClick={() => handlePageChange(currentPagePending - 1)}
          >
            Previous
          </Button>
          <Typography variant="body1">
            Page {currentPagePending} of {totalPagesPending}
          </Typography>
          <Button
            className="bg-pract_green"
            variant="contained"
            disabled={currentPagePending === totalPagesPending}
            onClick={() => handlePageChange(currentPagePending + 1)}
          >
            Next
          </Button>
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start mt-5 py-0 pr-0 pl-0 box-border gap-[24px_22px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border">
          {historyBookings == "isLoading" && <div><Loader></Loader></div>}


           {historyBookings && historyBookings !== "isLoading" && historyBookings.result&&  historyBookings.result.length > 0 &&
                historyBookings.result.map((booking) => (
                  <AppointmentCardHistory
                    key={booking.id}
                    booking={booking}
                    onBookingUpdate={handleBookingUpdate}
                  />
                ))
              }
              {historyBookings && historyBookings !== "isLoading" &&historyBookings.result &&  historyBookings.result.length === 0 && <div>No bookings available</div>}
        
        </div>
        <div className="flex flex-row items-center justify-center gap-[20px] mt-4">
          <Button
            className="bg-pract_green"
            variant="contained"
            disabled={currentPageHistory === 1}
            onClick={() => handlePageChange(currentPageHistory - 1)}
          >
            Previous
          </Button>
          <Typography variant="body1">
            Page {currentPageHistory} of {totalPagesHistory}
          </Typography>
          <Button
            className="bg-pract_green"
            variant="contained"
            disabled={currentPageHistory === totalPagesHistory}
            onClick={() => handlePageChange(currentPageHistory + 1)}
          >
            Next
          </Button>
        </div>
      </TabPanel>
    </div>
  );
};

export default AppointmentsHeader;