import axios from 'axios';
import authHeader from './AuthHeader'
import { interceptor } from '../services/Interceptor';
import { BASE_URL } from '../Constants';
const API_URL = BASE_URL;

class ConsumerService {
  async createConsumerPreference(body) {
    interceptor();
    return axios.post(BASE_URL + 'Consumer/CreateConsumerPreference', body, { headers: authHeader() });
  }
  async getFeaturedPractitioners(pageNumber, pageSize) {
    interceptor();
    const url = `${BASE_URL}Consumer/GetFeaturedPractitioners?&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axios.get(url, { headers: authHeader() });
  }
  async getPopularServices(pageNumber, pageSize) {
    interceptor();
    const url = `${BASE_URL}Consumer/GetPopularServices?&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axios.get(url, { headers: authHeader() });
  }
  async getPractitionerById(id) {
    interceptor();
    const url = `${BASE_URL}Practitioner/GetById?UserId=${id}`;
    return axios.get(url, { headers: authHeader() });
  }
  async getOfferedServicesByPractitionerId(id, pageNumber, pageSize) {
    const url = `${BASE_URL}OfferedServices/GetByPractitionerId?practitionerId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return axios.get(url, { headers: authHeader() });
  }
  async searchPractitioners(searchVal, categoryIds) {
    interceptor();
    const url = `${BASE_URL}Elastic/SearchPractitioner`;

    const queryParams = new URLSearchParams({ searchVal });
    categoryIds.forEach(id => queryParams.append('categoryIds', id));

    return axios.get(`${url}?${queryParams.toString()}`, { headers: authHeader() });
  }

  async searchServices(searchVal, categoryIds, sessionType, fromcost, tocost) {
    interceptor();
    const url = `${BASE_URL}Elastic/SearchService`;
    const params = new URLSearchParams();
    if (searchVal) params.append('searchVal', searchVal);
    if (sessionType) params.append('sessionType', sessionType);
    if (fromcost) params.append('fromcost', fromcost);
    if (tocost) params.append('tocost', tocost);
    // Add each categoryId
    if (categoryIds) {
      categoryIds.forEach(id => params.append('categoryIds', id));
    }
    return axios.get(`${url}?${params.toString()}`, { headers: authHeader() });
  }

  async getConsumerPendingAndUpcomingBookings(consumerId) {
    interceptor();
    const timeZoneOffset = ((new Date().getTimezoneOffset().toString()) / 60) * -1;
    const url = `${BASE_URL}Booking/GetConsumerPendingAndUpcomingBookings`;
    const params = { consumerId };
    const headers = { ...authHeader(), TimeZoneOffSet: timeZoneOffset };
    return axios.get(url, { headers, params });
  }
  async getConsumerUpcomingBookings(consumerId,pageNumber) {
    interceptor();
    const timeZoneOffset = ((new Date().getTimezoneOffset().toString()) / 60) * -1;
    const url = `${BASE_URL}Booking/GetConsumerUpcomingBookings`;
    const params = { consumerId,pageNumber };
    const headers = { ...authHeader(), TimeZoneOffSet: timeZoneOffset };
    return axios.get(url, { headers, params });
  }
  async getConsumerPendingBookings(consumerId,pageNumber) {
    interceptor();
    const timeZoneOffset = ((new Date().getTimezoneOffset().toString()) / 60) * -1;
    const url = `${BASE_URL}Booking/GetConsumerPendingBookings`;
    const params = { consumerId ,pageNumber};
    const headers = { ...authHeader(), TimeZoneOffSet: timeZoneOffset };
    return axios.get(url, { headers, params });
  }

  async getConsumerHistoryBookings(consumerId, pageNumber) {
    interceptor();
    const timeZoneOffset = ((new Date().getTimezoneOffset().toString()) / 60) * -1;
    const url = `${BASE_URL}Booking/GetConsumerHistoryBookings`;
    const params = { consumerId, pageNumber };
    const headers = { ...authHeader(), TimeZoneOffSet: timeZoneOffset };
    return axios.get(url, { headers, params });
  }

  // cancel confirmed booking by consumer
  async cancelBookingConsumer(BookingId) {
    interceptor();
    const timeZoneOffset = ((new Date().getTimezoneOffset().toString()) / 60) * -1;
    return axios.put(
      API_URL + "Booking/CancelBookingBYConsumer",
      {},
      {
        params: {
          bookingId: BookingId,
        },
        headers: {
          ...authHeader(),
          TimeZoneOffSet: timeZoneOffset,
        },
      }
    );
  }

  //update profile consumer
  async updateConsumerProfile(Id, FirstName, LastName, DateOfBirth, ProfileImage) {
    interceptor();
    const url = `${BASE_URL}Consumer/UpdateConsumerProfile`;

    // Create a new FormData instance
    const formData = new FormData();
    formData.append('Id', Id);
    formData.append('FirstName', FirstName);
    formData.append('LastName', LastName);
    formData.append('DateOfBirth', DateOfBirth);
    // if (ProfileImage) {
      formData.append('ProfileImage', ProfileImage); // Assuming ProfileImage is a file
    // }

    // Send the PUT request
    return axios.put(url, formData, {
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data'
      }
    });
  }

}


export default new ConsumerService