
import React, { useEffect, useState, useRef } from 'react';
import {
  TextField,
  IconButton,
} from "@mui/material";
import MessageContact from "../SubComponent/MessageContact";
import { collection, query, orderBy, onSnapshot, addDoc,updateDoc, serverTimestamp, where, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useLocation } from 'react-router-dom';
import Loader from '../SubComponent/Loader';
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import OTFBooking from '../SubComponent/OtfBooking';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import styles

import OtfBox from '../SubComponent/OtfBox';

import { acceptBooking, rejectBooking, resetAcceptBooking, resetRejectBooking, } from '../../Actions/Practitioner/PractitionerBookingAction';

const Inbox = () => {
 // const bookingId = booking?.id;
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [input, setInput] = useState('');
  const UserData = JSON.parse(localStorage.getItem("user"));
  const currentUid = UserData.user.id;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [currentUserDetail, setCurrentUserDetail] = useState(UserData ? {
    id: UserData.user.id,
    name: UserData.user.fullName,
    img: UserData.user.profileImage ? UserData.user.profileImage : null
  } : null);
  const [selectedContact, setSelectedContact] = useState(null);
  const location = useLocation();
  const acceptStatus = useSelector((state) => state.cancelBooking.acceptBooking);
  const rejectStatus = useSelector((state) => state.cancelBooking.rejectBooking);
  console.log(selectedContact, "selected contact");
  const otherUserDetailConsumer = useSelector((state) => state.addService.otherUserDetail);

  const [otherUserDetail, setOtherUserDetail] = useState(() => {
    if (location.state?.otherUserDetail) {
      return location.state.otherUserDetail;
    }
    else if (otherUserDetailConsumer) {
      return otherUserDetailConsumer;
    }
    else {
      return null;
    }
  });

  useEffect(() => {
    if (otherUserDetail && currentUserDetail) {
      // setSelectedContact(otherUserDetail);
      handleUserSetup(otherUserDetail, currentUserDetail);
    }
  }, [otherUserDetail])

  useEffect(() => {
    const usersQuery = query(
      collection(firestore, 'users_chat'),
      where('users', 'array-contains', currentUid)
    );
    const unsubscribe = onSnapshot(usersQuery, async (snapshot) => {
      const conversationsData = [];

      for (const docSnap of snapshot.docs) {
        const docId = docSnap.id;
        const users = docSnap.data().users;
        const otherUserId = users.find(user => user !== currentUid);

        if (otherUserId) {
          const userQuery = query(
            collection(firestore, 'users'),
            where('id', '==', otherUserId)
          );
          const userSnapshot = await getDocs(userQuery);
          let user = null;

          if (!userSnapshot.empty) {
            userSnapshot.forEach(doc => {
              user = { id: doc.id, ...doc.data() };
            });
          }

          const messagesRef = collection(docSnap.ref, 'messages');
          const messagesQuery = query(messagesRef, orderBy('timestamp', 'desc'));

          const unsubscribeMessages = onSnapshot(messagesQuery, (messagesSnapshot) => {
            const messagesData = messagesSnapshot.docs.map((messageDoc) => ({
              id: messageDoc.id,
              ...messageDoc.data(),
            }));

            if (user) {
              const conversationIndex = conversationsData.findIndex(conv => conv.docRef.id === docSnap.id);
              if (conversationIndex > -1) {
                conversationsData[conversationIndex].messages = messagesData;
              } else {
                conversationsData.push({
                  user,
                  messages: messagesData,
                  docRef: docSnap.ref,
                });
              }

              setConversations([...conversationsData]);
            }
          });
        }
      }
      setLoading(false);
    });

    return () => { unsubscribe() };
  }, [currentUid]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() === '') {
      return;
    }

    const currentConversation = conversations.find(conv => conv.user.id === selectedContact.id);

    if (currentConversation) {
      const { docRef } = currentConversation;

      try {
        const messagesCollectionRef = collection(docRef, 'messages');
        const currentDate = new Date();
        const utcDate = new Date(Date.UTC(
          currentDate.getUTCFullYear(),
          currentDate.getUTCMonth(),
          currentDate.getUTCDate(),
          currentDate.getUTCHours(),
          currentDate.getUTCMinutes(),
          currentDate.getUTCSeconds()
        ));

        const isoString = utcDate.toISOString();


        await addDoc(messagesCollectionRef, {
          timestamp: isoString,
          uid: currentUid,
          message: input,
          message_type: 'text',
          payload: {},
        });
        setInput('');
      } catch (error) {
        console.error("Error sending message:", error);
      }
    } else {
      console.error("No current conversation found for the selected contact.");
    }
  };

  const handleAccept = async (bookingId) => {
    try {
      const currentConversation = conversations.find(conv => conv.user.id === selectedContact.id);
  
      if (currentConversation) {
        const { docRef } = currentConversation;
        const messagesCollectionRef = collection(docRef, 'messages');
  
     
        const querySnapshot = await getDocs(
          query(messagesCollectionRef, where('payload.bookingId', '==', bookingId))
        );
  
        querySnapshot.forEach(async (doc) => {
         
          await updateDoc(doc.ref, {
            'payload.status': 'accepted'
          });
        });
  
        dispatch(acceptBooking(bookingId));
        toast.success('Booking accepted successfully!'); 
      }
    } catch (error) {
      console.error("Error accepting booking:", error);
      toast.error('Failed to accept booking.'); 
    }
  };
  
  const handleReject = async (bookingId) => {
    try {
      const currentConversation = conversations.find(conv => conv.user.id === selectedContact.id);
  
      if (currentConversation) {
        const { docRef } = currentConversation;
        const messagesCollectionRef = collection(docRef, 'messages');
  
       
        const querySnapshot = await getDocs(
          query(messagesCollectionRef, where('payload.bookingId', '==', bookingId))
        );
  
        querySnapshot.forEach(async (doc) => {
          
          await updateDoc(doc.ref, {
            'payload.status': 'rejected'
          });
        });
  
        dispatch(rejectBooking(bookingId));
        toast.error('Booking rejected successfully!'); 
      }
    } catch (error) {
      console.error("Error rejecting booking:", error);
      toast.error('Failed to reject booking.'); 
    }
  };
  
  useEffect(() => {
    if (acceptStatus.status === true || rejectStatus.status === true) {
      
      dispatch(resetAcceptBooking());
      dispatch(resetRejectBooking());

    }
  }, [acceptStatus, rejectStatus]);
  const sendOTF = async ({ bookingStartTime, bookingEndTime, title, otfBookingId, otfBookingType }) => {
    const currentConversation = conversations.find(conv => conv.user.id === selectedContact.id);

    if (currentConversation) {
      const { docRef } = currentConversation;
      try {
        const messagesCollectionRef = collection(docRef, 'messages');
        const convertToUTCString = (dateString) => {
          const date = new Date(dateString);
          return new Date(Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
          )).toISOString();
        };

        const bookingStartTimeUTC = convertToUTCString(bookingStartTime);
        const bookingEndTimeUTC = convertToUTCString(bookingEndTime);

        const currentDate = new Date();
        const utcDate = new Date(Date.UTC(
          currentDate.getUTCFullYear(),
          currentDate.getUTCMonth(),
          currentDate.getUTCDate(),
          currentDate.getUTCHours(),
          currentDate.getUTCMinutes(),
          currentDate.getUTCSeconds()
        ));

        const isoString = utcDate.toISOString();
        const expiryDate = new Date(utcDate);
        expiryDate.setUTCDate(expiryDate.getUTCDate() + 1);
        const expiryIsoString = expiryDate.toISOString();

        const payload = {
          status: 'pending',
          bookingType: otfBookingType,
          bookingStartTime: bookingStartTimeUTC,
          bookingEndTime: bookingEndTimeUTC,
          bookingId: otfBookingId,
          expiry: expiryIsoString
        };

        await addDoc(messagesCollectionRef, {
          message: 'Invitation has been sent successfully',
          messageType: 'booking',
          payload: payload,
          timestamp: isoString,
          uid: currentUid,
          title
        });
        setShowOtfCard(true);
      } catch (error) {
        console.error("Error sending OTF:", error);
      }
    } else {
      console.error("No current conversation found for the selected contact.");
    }
  };

  const handleOtfData = (otfData) => {
    sendOTF(otfData);
  };
  const handleContactClick = (contact) => {
    setSelectedContact(contact);
  };

  const getInitials = (name) => {
    if (!name) return '';
    const namesArray = name.split(' ');
    const initials = namesArray.map(name => name.charAt(0).toUpperCase()).join('');
    return initials;
  };
  const messagesContainerRef = useRef(null);

  useEffect(() => {
   
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [conversations, selectedContact]);
  console.log(selectedContact, "selected contact");
  console.log(conversations, "conversation");

  const handleUserSetup = async (otherUserDetail, currentUserDetail) => {
    try {
      const usersChatRef = collection(firestore, 'users_chat');
      const usersRef = collection(firestore, 'users');

      // Check if a document exists in 'users_chat' with both currentUserDetail.id and otherUserDetail.id in the 'users' array
      const chatQuery = query(
        usersChatRef,
        where('users', 'array-contains', (currentUserDetail.id && otherUserDetail.id))
      );
      const chatSnapshot = await getDocs(chatQuery);

      let chatDocumentExists = false;
      let existingChatDocId = null;

      chatSnapshot.forEach(doc => {
        const chatData = doc.data();
        if (chatData.users.includes(currentUserDetail.id) && chatData.users.includes(otherUserDetail.id)) {
          chatDocumentExists = true;
          existingChatDocId = doc.id; // Save the ID of the existing chat document
        }
      });

      if (!chatDocumentExists) {
        // Create a new document in 'users_chat' with both IDs
        const newChatDocRef = await addDoc(usersChatRef, {
          users: [currentUserDetail.id, otherUserDetail.id]
        });

        // Create messages sub-collection
        const messagesRef = collection(newChatDocRef, 'messages');
        // add an initial document
      //  await addDoc(messagesRef, { initial: true });
      } else {
        console.log('Existing chat document found:', existingChatDocId);
      }

      // Check if currentUserDetail.id exists in 'users'
      const currentUserQuery = query(usersRef, where('id', '==', currentUserDetail.id));
      const currentUserSnapshot = await getDocs(currentUserQuery);

      if (currentUserSnapshot.empty) {
        console.log('Creating new current user document');

        // Create a new document in 'users' if it doesn't exist
        await addDoc(usersRef, currentUserDetail);

        console.log('New current user document created');
      } else {
        console.log('Current user already exists');
      }

      // Check if otherUserDetail.id exists in 'users'
      const userQuery = query(usersRef, where('id', '==', otherUserDetail.id));
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        console.log('Creating new other user document');

        // Create a new document in 'users' if it doesn't exist
        await addDoc(usersRef, otherUserDetail);

        console.log('New other user document created');
      } else {
        console.log('Other user already exists');
      }

      setSelectedContact(otherUserDetail);
    } catch (error) {
      console.error("Error setting up user:", error);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevents adding a new line in the textarea
      sendMessage(e);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-col items-start justify-start leading-normal tracking-normal">
      {loading ? (
        <div className="flex h-[400px] w-full items-center justify-center bg-white">
          <Loader />
        </div>
      ) : (
        <main className="self-stretch flex flex-row flex-wrap items-start gap-[20px] justify-start max-w-full text-left text-xl text-black font-web-primary-h5-primary-dm-sans">
          {/* Left Column */}
          <div className="w-[320px] h-[82vh] shadow-[1px_0px_0px_rgba(0,_0,_0,_0.08)] bg-neutral-1 overflow-hidden flex flex-col items-start justify-start pt-0 px-0 box-border gap-4 max-w-full font-heading-h5 mq1050:pb-[213px] mq1050:box-border mq450:gap-[16px] mq450:pb-[138px] mq450:box-border border-r-[1px] border-gray-200">
            <div className="flex flex-row items-center justify-start py-[20px] px-3 h-[70px] border-b-[1px] border-gray-200 shadow-[0px_1px_0px_rgba(0,_0,_0,_0.08)] w-full">
              <h3 className="m-0 relative text-inherit leading-[150%] font-semibold font-inherit inline-block min-w-[99px] mq450:text-base mq450:leading-[24px]">
                Messages
              </h3>
              <div className="rounded-3xl bg-gray-gray200 flex flex-col items-start justify-start py-0.5 px-2 text-xs">
                <div className="relative leading-[150%] font-semibold inline-block min-w-[14px]">
                  {/* 12 */}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start py-0 gap-[8px]">
              {conversations && conversations.length > 0 ? (
                conversations.map(({ user, messages }) => (
                  <MessageContact
                    key={user.id}
                    contact={user}
                    isSelected={selectedContact && selectedContact.id === user.id}
                    messages={messages}
                    onClick={() => handleContactClick(user)}
                  />
                ))
              ) : (
                <div className='text-sm'>No conversation found!</div>
              )}
            </div>
          </div>

          {/* Right Column */}
          <div
            className={`flex-1 flex flex-col items-start gap-[20px] justify-start min-w-[479px] mq750:min-w-full mq450:gap-[32px] ${UserData.user.role === 0 ? 'h-[82vh]' : 'h-[85vh]'}`}
          >
            {selectedContact ? (
              <>
                <div className="flex flex-row items-center pl-2 pr-2 justify-between gap-[16px] py-[20px] h-[70px] border-b-[1px] border-gray-200 shadow-[0px_1px_0px_rgba(0,_0,_0,_0.08)] w-full">
                  <div className='flex flex-row items-center justify-start gap-[16px]'>
                    {selectedContact.img ? (
                      <img
                        className="h-[48px] w-[48px] relative rounded-3xs object-cover"
                        loading="lazy"
                        alt={selectedContact.name}
                        src={selectedContact.img}
                      />
                    ) : (
                      <div className="h-[48px] w-[48px] relative rounded-3xs flex items-center justify-center bg-gray-300 text-white font-bold">
                        {getInitials(selectedContact.name)}
                      </div>

                    )}
                    <h3 className="m-0 relative text-inherit leading-[150%] font-semibold font-inherit inline-block min-w-[99px] mq450:text-base mq450:leading-[24px]">
                      {selectedContact.name}
                    </h3>
                  </div>
                  {UserData && UserData.user && UserData.user.role === 1 && (
                    <div className="flex items-center justify-center h-[44px] w-[44px] rounded-full bg-[#70FBFB]">
                      <IconButton onClick={handleClickOpen}>
                        <AddCircleOutlineOutlinedIcon />
                      </IconButton>
                    </div>
                  )}
                </div>

                {/* Messages Container */}
                <div className="self-stretch flex flex-col pl-2 pr-2 items-start justify-start py-0 gap-[8px] h-full overflow-y-auto hide-scrollbar" ref={messagesContainerRef}>
                  {selectedContact && conversations.find(conv => conv.user.id === selectedContact.id)?.messages
                    .slice()
                    .reverse()
                    .map(({ id, message, uid, messageType, payload, timestamp, title }) => (
                      <div
                        key={id}
                        className={`flex flex-col ${uid === currentUid ? 'self-end items-end' : 'self-start items-start'} max-w-[70%]`}
                      >
                        {messageType === 'booking' ? (
                          <OtfBox
                            status={payload.status}
                            bookingType={payload.bookingType}
                            time={timestamp}
                            title={title}
                            isConsumer={UserData.user.role === 0}
                            onAccept={() => handleAccept(payload.bookingId)}
                            onReject={() => handleReject(payload.bookingId)}
                          />
                        ) : (
                          <div
                            className={`message flex flex-row px-[14px] py-[8px] rounded-sm text-sm ${UserData.user.role === 0
                              ? uid === currentUid
                                ? 'bg-darkorange text-white'
                                : 'bg-darkgreen text-white'
                              : uid === currentUid
                                ? 'bg-darkgreen text-white'
                                : 'bg-darkorange text-white'
                              }`}
                            style={{
                              wordBreak: 'break-word',
                              overflowWrap: 'break-word',
                            }}
                          >
                            <p className="m-0">{message}</p>
                          </div>
                        )}
                        <div className="text-xs text-gray-400 mt-1">
                          {new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                      </div>
                    ))}
                </div>

                {/* Input Container */}
                <div className="self-stretch flex flex-col items-center justify-center py-0 pl-2 pr-2 gap-[24px]">
                  <div className="self-stretch flex flex-row items-start justify-start py-0 box-border max-w-full">
                    <TextField
                      className="[border:none] bg-[transparent] flex-1 font-web-primary-h5-primary-dm-sans text-sm text-gray-300 max-w-full"
                      placeholder="Type a message"
                      variant="outlined"
                      multiline
                      maxRows={2}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={handleKeyDown}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={sendMessage}>
                            <img width="24px" height="24px" src="/iconsax.svg" />
                          </IconButton>
                        ),
                      }}
                      sx={{
                        "& fieldset": { borderColor: "#e2e8f0" },
                        "& .MuiInputBase-root": {
                          height: "48px",
                          backgroundColor: "#fff",
                          paddingRight: "0px",
                          borderRadius: "12px",
                          overflow: "hidden",
                          fontSize: "14px",
                        },
                        "& .MuiInputBase-input": {
                          color: "rgba(0, 0, 0, 0.4)",
                          overflow: "auto",
                          scrollbarWidth: "none",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center w-full h-full">
                <h4 className="text-center text-gray-300 font-normal">
                  Please select a conversation from the left pane to start chatting
                </h4>
              </div>
            )}
          </div>
          <OTFBooking
            open={open}
            onClose={handleClose}
            currentUid={currentUid}
            selectedContact={selectedContact || null}
            onSendOtf={handleOtfData}

          />
        </main>

      )}
      <style jsx>{`
        .hide-scrollbar {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }

        .hide-scrollbar::-webkit-scrollbar {
          display: none; /* Chrome, Safari, and Opera */
        }
      `}</style>
    </div>
  );
};

export default Inbox;
