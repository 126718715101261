import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Button,
  OutlinedInput
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { signUp, resetSignupState } from "../Actions/Auth/SignupAction";
import { getAll } from "../Actions/Catagories/GetCategoriesAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/SubComponent/Loader";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';


const PractitionerSignUp = () => {
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    category: [],
    contactNo: phone,
    password: "",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const signUpState = useSelector(state => state.signup);
  const allCategories = useSelector(state => state.getCategories.getAll);
  useEffect(() => {
    dispatch(resetSignupState());
    dispatch(getAll());
  }, []);
  useEffect(() => {
    const UserData = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    if (UserData && UserData.user && UserData.user.role === 0) {
      navigate("/access-denied")
    }
  }, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "category") {
      const updatedCategories = value.slice(0, 2);
      setFormData({
        ...formData,
        [name]: updatedCategories,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const fullNameParts = formData.fullName.split(' ');
      const firstName = fullNameParts[0];
      const lastName = fullNameParts.slice(1).join(' ');
      const body = {
        email: formData.email,
        password: formData.password,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phone,
        role: 1,
      //  selectedCategoryIds: formData.category,
      };
      dispatch(signUp(body));
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validate Full Name
    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required";
      valid = false;
    }

    // Validate Email
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Invalid Email Format";
      valid = false;
    }

    // Validate Category
    // if (formData.category.length == 0) {
    //   newErrors.category = "Category is required";
    //   valid = false;
    // }

    // Validate Password
    if (!isValidPassword(formData.password)) {
      newErrors.password =
        "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character ";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidPassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const NavigateToHome = () => {
    navigate("/practitioner-landing-page");
  };
  const navigateToEmailVerification = () => {
    navigate("/practitioner-sign-up-email-verification");
  };

  //country button ui
  const phoneInputRef = useRef(null);

  useEffect(() => {
    if (phoneInputRef.current) {
      const selectButton = phoneInputRef.current.querySelector(".react-international-phone-country-selector-button");
      if (selectButton) {
        selectButton.style.height = "53px";
      }
    }
  }, []);

  return (
    <div className="w-full relative bg-m3-sys-light-on-primary overflow-hidden flex flex-row items-start justify-start py-10 pr-10 pl-[140px] box-border gap-[47px] leading-[normal] tracking-[normal] text-left text-smi text-m3-black font-poppins mq750:gap-[23px] mq750:pl-[70px] mq750:box-border mq450:pl-5 mq450:box-border mq1125:flex-wrap">
      <div className="h-[57.5px] w-[404px] hidden flex-col items-start justify-start max-w-full">
        <div className="w-10 flex flex-row items-start justify-start">
          <div className="h-5 flex-1 relative font-medium inline-block">
            Name
          </div>
        </div>
        <div className="self-stretch h-8 rounded-3xs box-border overflow-hidden shrink-0 border-[1px] border-solid border-gainsboro-200" />
      </div>
      <div className="w-[] flex flex-col items-start justify-start pt-8 px-0 pb-0 box-border min-w-[480px] max-w-full mq750:min-w-full mq1125:flex-1">
        <form className="m-0 self-stretch flex flex-col items-start justify-start gap-[58px] max-w-full mq750:gap-[20px]">
          <div className="w-60 flex flex-row items-end justify-start gap-[12.6px]">
            <img
              className="h-12 w-[48.2px] relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/the-healers-logo-1.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[12.5px]">
              <img
                className="self-stretch h-[22.9px] relative max-w-full overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/the-healers4@2x.png"
              />
            </div>
          </div>
          <div className="w-[480px] flex-1 overflow-x-auto flex flex-col items-start justify-start gap-[32px] max-w-full mq750:gap-[16px]">
            <div className="self-stretch relative text-base leading-[24px] font-web-secondary-underline-subtitle2-dm-sans text-gray-200 text-left">
              Enter your credentials to create an account
            </div>
            <div className="self-stretch flex flex-row items-start justify-start max-w-full">
              <TextField
                className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-secondary-underline-subtitle2-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
                name="fullName"
                label="Full Name"
                value={formData.fullName}
                onChange={handleInputChange}
                error={!!errors.fullName}
                helperText={errors.fullName}
                required
              />
              <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-secondary text-left">
                  Helper text
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start max-w-full">
              <TextField
                className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-secondary-underline-subtitle2-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                error={!!errors.email}
                helperText={errors.email}
                required
              />
              <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0">
                <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-secondary text-left">
                  Helper text
                </div>
              </div>
            </div>
            {/* <div className="self-stretch flex flex-row items-start justify-start max-w-full">
              <FormControl
                className="h-[53px] flex-1 font-web-secondary-underline-subtitle2-dm-sans text-base text-m3-ref-neutral-neutral20-200 max-w-full"
                error={!!errors.category}
                required
              >
                <InputLabel>Choose Category</InputLabel>
                <Select
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  multiple
                  input={<OutlinedInput label="Choose Category" />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        width: 250,
                      },
                    },
                  }}
                >
                  {allCategories === "isLoading" ? (
                    <MenuItem>loading...</MenuItem>
                  ) : (
                    allCategories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
                {errors.category && (
                  <FormHelperText>{errors.category}</FormHelperText>
                )}
              </FormControl>
              <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-secondary text-left">
                  Helper text
                </div>
              </div>
            </div> */}
            <div className="flex flex-row items-start justify-start w-full min-h-[53px]">
              <div className="flex-1 h-[53px] w-full">
                <div ref={phoneInputRef} style={{ width: "100%" }}>
                  <PhoneInput
                    defaultCountry="ca"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    inputStyle={{ width: "100%", height: "53px" }}
                    containerStyle={{ width: "100%", height: "53px" }}
                  />
                </div>
              </div>

              <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-secondary text-left">
                  Helper text
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start max-w-full">
              <TextField
                className="[border:none] bg-[transparent] h-[53px] flex-1 font-web-secondary-underline-subtitle2-dm-sans text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full mq750:h-auto"
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={formData.password}
                onChange={handleInputChange}
                error={!!errors.password}
                helperText={errors.password}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowPasswordClick}
                        aria-label="toggle password visibility"
                      >
                        <Icon>
                          {showPassword ? "visibility_off" : "visibility"}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
                <div className="self-stretch relative text-xs tracking-[0.4px] leading-[166%] font-components-helper-text text-text-secondary text-left">
                  Helper text
                </div>
              </div>
            </div>
            <Button
              className="self-stretch h-[60px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border mt-6"
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "18",
                background: "#006a6a",
                border: "#006a6a solid 1px",
                borderRadius: "4px",
                "&:hover": { background: "#006a6a" },
              }}
              onClick={handleSubmit}
            >
              {signUpState.signUp === "isLoading" ? (
                <div className="mt-2">
                  <Loader />
                </div>
              ) : signUpState.signUp.status &&
                signUpState.signUp.status == true ? (
                navigateToEmailVerification()
              ) : (
                "Sign Up"
              )}
            </Button>
            <div className="flex flex-row items-start justify-start gap-[8px]">
              <div className="relative text-sm leading-[22px] font-web-secondary-underline-subtitle2-dm-sans text-gray-200 text-left whitespace-nowrap">
                Already have an account?
              </div>
              <a
                href="/practitioner-sign-in"
                className="relative text-sm leading-[22px] font-web-secondary-underline-subtitle2-dm-sans text-material-theme-source-primary text-left inline-block min-w-[45px] whitespace-nowrap"
              >
                Sign In
              </a>
            </div>
            <Button
              className="w-[154px] flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
              startIcon={
                <img width="16px" height="16px" src="/navigate-before.svg" />
              }
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#434343",
                fontSize: "14",
                background: "#fff",
                border: "#d9d9d9 solid 1px",
                borderRadius: "4px",
                "&:hover": { background: "#fff" },
                width: 154,
              }}
              onClick={NavigateToHome}
            >
              Back to home
            </Button>
          </div>
        </form>
      </div>
      <div className="h-[944px] flex-1 relative min-w-[476px] max-w-full mq750:min-w-full">
        <img
          className="absolute h-full top-[0px] bottom-[0px] left-[93px] rounded-3xl max-h-full w-[640px] object-cover"
          loading="lazy"
          alt=""
          src="/chrislee70l1tdai6rmunsplash-2@2x.png"
        />
        <div className="absolute top-[634px] left-[0px] backdrop-filter backdrop-blur-[22.2px] rounded-[50%] bg-teal-300 bg-opacity-35 box-border w-[187px] h-[187px] z-[1] border-[0px] border-solid border-teal-200" />
      </div>
    </div>
  );
};

export default PractitionerSignUp;