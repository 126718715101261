import { Menu, MenuItem, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Logout, resetLogoutState } from "../../Actions/Auth/LogoutAction";
import { useDispatch, useSelector } from "react-redux";
import ConsumerNotification from "../SubComponent/ConsumerNotification";

const ConsumerHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const UserData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  const dispatch = useDispatch();
  const localDeviceId = JSON.parse(localStorage.getItem("deviceId"));
  const [deviceId, setDeviceId] = useState(localDeviceId ? localDeviceId : null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const logout = useSelector((state) => state.logout.logout);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getDeviceID = () => {
    if (navigator && navigator.userAgent) {
      return navigator.userAgent;
    } else {
      return "default_device_id";
    }
  };
  const NavigateToHome = () => {
    localStorage.removeItem('user');
    navigate("/consumer-landing-page");
    localStorage.clear();
  };
  useEffect(() => {
    if (logout && logout !== "isLoading" && logout.length !== 0 && logout.status === true) {
      NavigateToHome();
      dispatch(resetLogoutState());
    }
  }, [logout]);
  useEffect(() => {
    const id = getDeviceID();
    setDeviceId(id);
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const NavigateToProfile = () => {
    navigate("/consumer-profile");
  };
  const handleLogout = () => {
    if (deviceId) {
      const userId = UserData.user.id;
      dispatch(Logout(deviceId, userId));
    }
  };
  const handleLogoClick = () => {
    if (location.pathname === '/consumer-profile') {
      navigate('/search-results-practitioners');
    } else {
      navigate('/consumer-landing-page');
    }
  };
  const NavigateToSignup = () => {
    navigate('/consumer-sign-up')
  }
  const NavigateToSignin = () => {
    navigate('/consumer-sign-in')
  }
  const NavigateToDashboard = () => {
    navigate('/practitioner');
  }
  return (
    <header className="self-stretch flex w-full justify-center shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] bg-neutral-1 pl-[60px] flex flex-row items-center py-6 px-6 sticky top-[0] z-[99] border-[1px] border-solid border-gainsboro-100 lg:gap-[362px] mq450:gap-[90px] mq750:gap-[181px]">
      <div className="selfstreach flex flex-row  w-[1400px] justify-between px-[20px]">
        <div
          className="flex flex-col items-start justify-start px-0 pb-0 box-border cursor-pointer"
          onClick={handleLogoClick}
        >
          <div className="flex flex-row items-end justify-start gap-[12px]">
            <img
              className="h-[47px] w-[47.4px] relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/the-healers-logo2.svg"
            />
            <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[12.2px]">
              <img
                className="self-stretch h-[22.5px] relative max-w-full overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/the-healers3.svg"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end">
          {(UserData && UserData.user) ?
            UserData.user.role === 0 ?
              (
                <>
                <ConsumerNotification />
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className="flex items-center"
                  >
                    {(UserData && UserData.user.role === 0 && UserData.user.profileImage) ? (
                      <img
                        className="ml-2"
                        width="41px"
                        height="41px"
                        src={UserData.user.profileImage}
                        alt="Avatar"
                      />
                    ) : (
                      <img
                        className="ml-2"
                        width="41px"
                        height="41px"
                        src="/Rectangle 39977.svg"
                        alt="Avatar"
                      />
                    )}
                    <span
                      style={{ textTransform: "none" }}
                      className="ml-2 text-base text-black font-inherit"
                    >
                      {UserData.user.fullName}
                    </span>
                    <img
                      className="ml-2"
                      width="24px"
                      height="24px"
                      src="/keyboard-arrow-down.svg"
                      alt="Dropdown"
                    />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={NavigateToProfile}>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </>
              )
              :
              (<div className="self-stretch w-[260px] flex flex-row items-start justify-start gap-[16px]">
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >

                  {UserData.user.profileImage ? (
                    <img
                      className="ml-2"
                      width="41px"
                      height="41px"
                      src={UserData.user.profileImage}
                      alt="Avatar"
                    />
                  ) : (
                    <img
                      className="ml-2"
                      width="41px"
                      height="41px"
                      src="/placeholder_profileImage.png"
                      alt="Avatar"
                    />
                  )}
                  <span
                    style={{ textTransform: "none" }}
                    className="ml-2 text-base text-black font-inherit"
                  >
                    {UserData.user.fullName}
                  </span>
                  <img
                    className="ml-2"
                    width="24px"
                    height="24px"
                    src="/keyboard-arrow-down.svg"
                    alt="Dropdown"
                  />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={NavigateToDashboard}>
                    Dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>)
            : (
              <div className="self-stretch w-64 flex flex-row items-center justify-start gap-[16px]">
                <div className="w-[79px] flex flex-col items-center justify-start px-0 pb-0 box-border">
                  <Button
                    className="self-stretch h-[38px]"
                    disableElevation={true}
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      color: "#000",
                      fontSize: "18",
                      borderRadius: "4px",
                      height: 38,
                      borderColor: "transparent",
                      "&:hover": { borderColor: "#f28500" },
                    }}
                    onClick={NavigateToSignin}
                  >
                    Login
                  </Button>
                </div>
                <Button
                  className="self-stretch flex-1 [filter:drop-shadow(0px_2px_0px_rgba(0,_0,_0,_0.02))]"
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "#000",
                    fontSize: "18",
                    borderColor: "#000",
                    borderRadius: "8px",
                    "&:hover": { borderColor: "#f28500" },
                  }}
                  onClick={NavigateToSignup}
                >
                  Sign Up Now
                </Button>
              </div>
            )
          }
        </div>
      </div>
    </header>

  );
};

export default ConsumerHeader;
