//SignUp
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_PENDING = "SIGNUP_PENDING";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const RESET_SIGNUP_STATE = 'RESET_SIGNUP_STATE';

//ConfirmSignUp
export const CONFIRM_SIGNUP_SUCCESS = "CONFIRM_SIGNUP_SUCCESS";
export const CONFIRM_SIGNUP_PENDING = "CONFIRM_SIGNUP_PENDING";
export const CONFIRM_SIGNUP_FAIL = "CONFIRM_SIGNUP_FAIL";
export const RESET_CONFIRM_STATE = 'RESET_CONFIRM_STATE';

//ForgotPassword
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_PENDING = "FORGOT_PASSWORD_PENDING";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const RESET_FORGOT_PASSWORD_STATE = 'RESET_FORGOT_PASSWORD_STATE';

//ConfirmForgotPassword
export const CONFIRM_FORGOT_PASSWORD_SUCCESS = "CONFIRM_FORGOT_PASSWORD_SUCCESS";
export const CONFIRM_FORGOT_PASSWORD_PENDING = "CONFIRM_FORGOT_PASSWORD_PENDING";
export const CONFIRM_FORGOT_PASSWORD_FAIL = "CONFIRM_FORGOT_PASSWORD_FAIL";

//Resend OTP
export const RESEND_SUCCESS = "RESEND_SUCCESS";
export const RESEND_PENDING = "RESEND_PENDING";
export const RESEND_FAIL = "RESEND_FAIL";


//SignIn
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_PENDING = "SIGNIN_PENDING";
export const SIGNIN_FAIL = "SIGNIN_FAIL";
export const RESET_SIGNIN_STATE = 'RESET_SIGNIN_STATE';

//Categories
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_PENDING = "GET_CATEGORIES_PENDING";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

//messages
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//consumer preferences
export const PREFERENCE_SUCCESS = "PREFERENCE_SUCCESS";
export const PREFERENCE_PENDING = "PREFERENCE_PENDING";
export const CREATE_PREFERENCE_SUCCESS = "CREATE_PREFERENCE_SUCCESS";
export const CREATE_PREFERENCE_PENDING = "CREATE_PREFERENCE_PENDING";
export const CREATE_PREFERENCE_FAIL = "CREATE_PREFERENCE_FAIL";


//practitioner profile detailing
export const PROFILE_DETAILING_SUCCESS = "PROFILE_DETAILING_SUCCESS";
export const PROFILE_DETAILING_PENDING = "PROFILE_DETAILING_PENDING";
export const PROFILE_DETAILING_FAIL = "PROFILE_DETAILING_FAIL";
export const RESET_PROFILE_DETAILING = "RESET_PROFILE_DETAILING";
//practitioner categories
export const ADD_CATEGORY_SUCCESS = "PROFILE_DETAILING_SUCCESS";
export const ADD_CATEGORY_PENDING = "PROFILE_DETAILING_PENDING";
export const ADD_CATEGORY_FAIL = "PROFILE_DETAILING_FAIL";
export const RESET_ADD_CATEGORY = "RESET_ADD_CATEGORY";
//practitioner bio
export const ADD_BIO_SUCCESS = "PROFILE_DETAILING_SUCCESS";
export const ADD_BIO_PENDING = "PROFILE_DETAILING_PENDING";
export const ADD_BIO_FAIL = "PROFILE_DETAILING_FAIL";
export const RESET_ADD_BIO = "RESET_ADD_BIO";
//consumer featured practitioners
export const GET_PRACTITIONER_SUCCESS = "GET_PRACTITIONER_SUCCESS";
export const GET_PRACTITIONER_PENDING = "GET_PRACTITIONER_PENDING";
export const GET_PRACTITIONER_FAIL = "GET_PRACTITIONER_FAIL";

//consumer popular services
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_PENDING = "GET_SERVICES_PENDING";
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL";

//consumer practitioner by id
export const GET_PRACTITIONER_ID_SUCCESS = "GET_PRACTITIONER_ID_SUCCESS";
export const GET_PRACTITIONER_ID_PENDING = "GET_PRACTITIONER_ID_PENDING";
export const GET_PRACTITIONER_ID_FAIL = "GET_PRACTITIONER_ID_FAIL";

//consumer service by practitioner
export const GET_SERVICES_PRACTITIONER_SUCCESS = "GET_SERVICES_PRACTITIONER_SUCCESS";
export const GET_SERVICES_PRACTITIONER_PENDING = "GET_SERVICES_PRACTITIONER_PENDING";
export const GET_SERVICES_PRACTITIONER_FAIL = "GET_SERVICES_PRACTITIONER_FAIL";

//practitioner add service
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_PENDING = "ADD_SERVICE_PENDING";
export const ADD_SERVICE_FAIL = "ADD_SERVICE_FAIL";
export const RESET_ADD_SERVICE = "RESET_ADD_SERVICE";
export const SELECTION_SUCCESS = "SELECTION_SUCCESS";
export const SELECTION_RESET = "SELECTION_RESET";
export const SERVICE_DATA = "SERVICE_DATA"

//practitioner update service
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_PENDING = "UPDATE_SERVICE_PENDING";
export const UPDATE_SERVICE_FAIL = "UPDATE_SERVICE_FAIL";
export const RESET_UPDATE_SERVICE = "RESET_UPDATE_SERVICE";

//practitioner consumer update profile
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_PENDING = "UPDATE_PROFILE_PENDING";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const RESET_UPDATE_PROFILE = "RESET_UPDATE_PROFILE";

//practitioner get schedule
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS";
export const GET_SCHEDULE_PENDING = "GET_SCHEDULE_PENDING";
export const GET_SCHEDULE_FAIL = "GET_SCHEDULE_FAIL";
export const SET_SCHEDULE = "SET_SCHEDULE";
export const RESET_SCHEDULE = "RESET_SCHEDULE";

//practitioner add schedule
export const ADD_SCHEDULE_SUCCESS = "ADD_SCHEDULE_SUCCESS";
export const ADD_SCHEDULE_PENDING = "ADD_SCHEDULE_PENDING";
export const ADD_SCHEDULE_FAIL = "ADD_SCHEDULE_FAIL";
export const RESET_ADD_SCHEDULE = "RESET_ADD_SCHEDULE";

//practitioner update schedule
export const UPDATE_SCHEDULE_SUCCESS = "UPDATE_SCHEDULE_SUCCESS";
export const UPDATE_SCHEDULE_PENDING = "UPDATE_SCHEDULE_PENDING";
export const UPDATE_SCHEDULE_FAIL = "UPDATE_SCHEDULE_FAIL";
export const RESET_UPDATE_SCHEDULE = "RESET_UPDATE_SCHEDULE";

//consumer add schedule
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS";
export const ADD_BOOKING_PENDING = "ADD_BOOKING_PENDING";
export const ADD_BOOKING_FAIL = "ADD_BOOKING_FAIL";
export const RESET_ADD_BOOKING = "RESET_ADD_BOOKING";

//practitioner get booking
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_PENDING = "GET_BOOKING_PENDING";
export const GET_BOOKING_FAIL = "GET_BOOKING_FAIL";

export const GET_UPCOMING_BOOKING_SUCCESS = "GET_UPCOMING_BOOKING_SUCCESS";
export const GET_UPCOMING_BOOKING_PENDING = "GET_UPCOMING_BOOKING_PENDING";
export const GET_UPCOMING_BOOKING_FAIL = "GET_UPCOMING_BOOKING_FAIL";

export const GET_HISTORY_BOOKING_SUCCESS = "GET_HISTORY_BOOKING_SUCCESS";
export const GET_HISTORY_BOOKING_PENDING = "GET_HISTORY_BOOKING_PENDING";
export const GET_HISTORY_BOOKING_FAIL = "GET_HISTORY_BOOKING_FAIL";

//practitioner cancel booking
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS";
export const CANCEL_BOOKING_PENDING = "CANCEL_BOOKING_PENDING";
export const CANCEL_BOOKING_FAIL = "CANCEL_BOOKING_FAIL";
export const RESET_CANCEL_BOOKING = "RESET_CANCEL_BOOKING";

//practitioner accept booking
export const ACCEPT_BOOKING_SUCCESS = "ACCEPT_BOOKING_SUCCESS";
export const ACCEPT_BOOKING_PENDING = "ACCEPT_BOOKING_PENDING";
export const ACCEPT_BOOKING_FAIL = "ACCEPT_BOOKING_FAIL";
export const RESET_ACCEPT_BOOKING = "RESET_ACCEPT_BOOKING";

//practitioner reject booking
export const REJECT_BOOKING_SUCCESS = "REJECT_BOOKING_SUCCESS";
export const REJECT_BOOKING_PENDING = "REJECT_BOOKING_PENDING";
export const REJECT_BOOKING_FAIL = "REJECT_BOOKING_FAIL";
export const RESET_REJECT_BOOKING = "RESET_REJECT_BOOKING";

//consumer get elastic search practitioners
export const GET_ELASTIC_PRACTITIONERS_SUCCESS = "GET_ELASTIC_PRACTITIONERS_SUCCESS";
export const GET_ELASTIC_PRACTITIONERS_PENDING = "GET_ELASTIC_PRACTITIONERS_PENDING";
export const GET_ELASTIC_PRACTITIONERS_FAIL = "GET_ELASTIC_PRACTITIONERS_FAIL";
export const RESET_ELASTIC_PRACTITIONERS = "RESET_ELASTIC_PRACTITIONERS";

//consumer get elastic search services
export const GET_ELASTIC_SERVICES_SUCCESS = "GET_ELASTIC_SERVICES_SUCCESS";
export const GET_ELASTIC_SERVICES_PENDING = "GET_ELASTIC_SERVICES_PENDING";
export const GET_ELASTIC_SERVICES_FAIL = "GET_ELASTIC_SERVICES_FAIL";
export const RESET_ELASTIC_SERVICES = "RESET_ELASTIC_SERVICES";



//consumer get booking
export const GET_CONSUMER_BOOKING_SUCCESS = "GET_CONSUMER_BOOKING_SUCCESS";
export const GET_CONSUMER_BOOKING_PENDING = "GET_CONSUMER_BOOKING_PENDING";
export const GET_CONSUMER_BOOKING_FAIL = "GET_CONSUMER_BOOKING_FAIL";


// consumer history booking
export const GET_CONSUMER_HISTORY_BOOKING_SUCCESS = "GET_CONSUMER_HISTORY_BOOKING_SUCCESS";
export const GET_CONSUMER_HISTORY_BOOKING_PENDING = "GET_CONSUMER_HISTORY_BOOKING_PENDING";
export const GET_CONSUMER_HISTORY_BOOKING_FAIL = "GET_CONSUMER_HISTORY_BOOKING_FAIL";

// consumer upcoming booking
export const GET_CONSUMER_UPCOMING_BOOKING_SUCCESS = "GET_CONSUMER_UPCOMING_BOOKING_SUCCESS";
export const GET_CONSUMER_UPCOMING_BOOKING_PENDING = "GET_CONSUMER_UPCOMING_BOOKING_PENDING";
export const GET_CONSUMER_UPCOMING_BOOKING_FAIL = "GET_CONSUMER_UPCOMING_BOOKING_FAIL";

// consumer pending booking
export const GET_CONSUMER_PENDING_BOOKING_SUCCESS = "GET_CONSUMER_PENDING_BOOKING_SUCCESS";
export const GET_CONSUMER_PENDING_BOOKING_PENDING = "GET_CONSUMER_PENDING_BOOKING_PENDING";
export const GET_CONSUMER_PENDING_BOOKING_FAIL = "GET_CONSUMER_PENDING_BOOKING_FAIL";


//consumer selected profile tab
export const SET_SIDEBAR_TAB = "SET_SIDEBAR_TAB";
export const RESET_SIDEBAR_TAB = "RESET_SIDEBAR_TAB";

//logout
export const LOGOUT_PENDING = "LOGOUT_PENDING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

//reset logout state
export const RESET_LOGOUT_STATE = "RESET_LOGOUT_STATE";

//get notification
export const GET_NOTIFICATION_PENDING = "GET_NOTIFICATION_PENDING";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";

//delete notification
export const DELETE_NOTIFICATION_PENDING = "DELETE_NOTIFICATION_PENDING";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";

//update notification status
export const UPDATE_NOTIFICATION_PENDING = "UPDATE_NOTIFICATION_PENDING";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL";


export const OTHER_USER_DETAIL_SUCCESS = "OTHER_USER_DETAIL_SUCCESS"
