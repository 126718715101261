import { useCallback } from "react";
import {
  TextField,
  Button,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import SidebarLight from "../components/Layout/SidebarLight";
import ConsumerHeader from "../components/Layout/ConsumerHeader";
import ConsumerSchedule from "../components/Layout/ConsumerSchedule";
import { useDispatch, useSelector } from "react-redux";
import Inbox from "../components/Layout/Inbox";
import DateOfBirthField from "../components/SubComponent/DateOfBirthField";
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { updateProfileConsumer } from "../Actions/Consumer/ConsumerProfileUpdateAction";
import Loader from "../components/SubComponent/Loader";

const ConsumerProfile = () => {
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [errors, setErrors] = useState({});
  const [updateDisable, setUpdateDisable] = useState(true);

  const [formValues, setFormValues] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: null,
    image: ""
  });
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sideBar = useSelector(state => state.sideBar.sideBar);
  // const onSidebarTileContainerClick = useCallback(() => {
  //   navigate("/consumer-schedule-upcoming");
  // }, [navigate]);
  const UserData = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState(UserData ? UserData : null);

  const [profileImage, setProfileImage] = useState(user ? user.user.profileImage : null);
  // const onSidebarTileContainerClick1 = useCallback(() => {
  //   navigate("/consumer-inbox");
  // }, [navigate]);
  const inbox = location.state?.inbox;
  const otherUserDetail = location.state?.otherUserDetail;
  const updateConsumerProfile = useSelector((state) => state.updateProfileConsumer.updateProfileConsumer);
  useEffect(() => {
    if (UserData && updateConsumerProfile && updateConsumerProfile !== "isLoading" && updateConsumerProfile.result.length !== 0) {
      const updatedUserData = updateConsumerProfile.result[0];
      if (UserData.user) {
        UserData.user.firstName = updatedUserData.firstName;
        UserData.user.lastName = updatedUserData.lastName;
        UserData.user.fullName = updatedUserData.fullName;
        UserData.user.dateOfBirth = updatedUserData.dateOfBirth;
        UserData.user.profileImage = updatedUserData.profileImage;

        localStorage.setItem('user', JSON.stringify(UserData));
        setUpdateDisable(true);
      } else {
        console.error('User data is missing in localStorage.');
      }

    } else {
      console.error('Update response is invalid or no result found.');
    }

  }, [updateConsumerProfile])

  useEffect(() => {
    if (user) {
      const userDateOfBirth = UserData.user.dateOfBirth;
      const dayjsDateOfBirth = userDateOfBirth ? dayjs(userDateOfBirth) : null;
      setFormValues({
        firstName: UserData.user.firstName || '',
        lastName: UserData.user.lastName || '',
        email: UserData.user.email || '',
        id: UserData.user.id || '',
        dateOfBirth: UserData.user.dateOfBirth ? UserData.user.dateOfBirth : null,
        image: UserData.user.ProfileImage
      });
      setDateOfBirth(dayjsDateOfBirth);
    }
  }, [user]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
    setUpdateDisable(false);
  };
  const handleDateChange = (newDate) => {
    setDateOfBirth(newDate);
    const formattedDate = newDate.toISOString();
    setFormValues(prevValues => ({
      ...prevValues,
      dateOfBirth: formattedDate,
    }));
    setUpdateDisable(false);
  };
  const validateForm = (values) => {
    const errors = {};
    if (!values.firstName) errors.firstName = 'First name is required';
    if (!values.lastName) errors.lastName = 'Last name is required';
    if (!values.dateOfBirth) errors.dateOfBirth = 'Date of birth is required';
    return errors;
  };

  const handleSaveChanges = () => {
    const formErrors = validateForm(formValues);
    if (Object.keys(formErrors).length === 0) {
      const Id = formValues.id;
      const FirstName = formValues.firstName;
      const LastName = formValues.lastName;
      const DateOfBirth = formValues.dateOfBirth;
      const ProfileImage = formValues.image;
      dispatch(updateProfileConsumer(Id, FirstName, LastName, DateOfBirth, ProfileImage));
    } else {

      setErrors(formErrors);
    }
  }

  const handleImageUpdate = (newImage) => {
    setProfileImage(newImage);
    setFormValues(prevValues => ({
      ...prevValues,
      image: newImage,
    }));
    setUpdateDisable(false);
  };
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-1 box-border gap-[20px] leading-[normal] tracking-[normal] mq750:gap-[10px]">
      <ConsumerHeader></ConsumerHeader>
      <main className="self-stretch flex flex-row items-start justify-center  box-border max-w-full">
        <section className="min-w-[1312px] flex flex-row items-start justify-center gap-[32px] max-w-full mq1050:flex-wrap mq750:gap-[16px]">
          <SidebarLight
            personOutline="/person_outline2.svg"
            eventNote="/event_note2.svg"
            forum="/forum.svg"
            inbox={inbox}
            image={profileImage}
            onImageChange={handleImageUpdate}

          />
          <div className="flex-1 w-[960px] flex flex-col items-start justify-start px-0 pb-0 box-border min-w-[551px] max-w-full mq750:min-w-full">
            {sideBar == 1 &&
              <ConsumerSchedule></ConsumerSchedule>
            }
            {sideBar == 2 &&
              <Inbox otherUserDetail={otherUserDetail} />
            }
            {sideBar == 0 &&
              <form className="m-0 self-stretch flex flex-col items-start pt-4 justify-start gap-[32px] max-w-full mq450:gap-[16px]">
                <h1 className="m-0 relative text-13xl leading-[48px] font-bold font-web-secondary-body1 text-m3-black text-left mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
                  Account details
                </h1>
                <div className="self-stretch h-px flex flex-row items-start justify-start pt-px px-0 pb-0 box-border max-w-full">

                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-[32px] max-w-full mq750:flex-wrap mq450:gap-[16px]">
                  <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
                    <TextField
                      id="standard-basic"
                      name="firstName"
                      label="First Name"
                      variant="standard"
                      className="w-full h-full"
                      InputProps={{ style: { height: '100%' } }}
                      InputLabelProps={{ style: { height: '100%' } }}
                      value={formValues.firstName}
                      onChange={handleChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                      sx={{
                        '.MuiInputBase-root': {
                          height: '100%',
                        },
                        '.MuiInputLabel-root': {
                          transform: 'translate(0, 1.5px) scale(1)',
                          '&.MuiInputLabel-shrink': {
                            transform: 'translate(0, -1.5px) scale(0.75)',
                          }
                        },
                        '.MuiFormControl-root': {
                          height: '100%',
                        }
                      }}
                    />
                  </div>

                  <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
                    <TextField
                      id="standard-basic"
                      name="lastName"
                      label="Last Name"
                      variant="standard"
                      className="w-full h-full"
                      InputProps={{ style: { height: '100%' } }}
                      InputLabelProps={{ style: { height: '100%' } }}
                      value={formValues.lastName}
                      onChange={handleChange}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                      sx={{
                        '.MuiInputBase-root': {
                          height: '100%',
                        },
                        '.MuiInputLabel-root': {
                          transform: 'translate(0, 1.5px) scale(1)',
                          '&.MuiInputLabel-shrink': {
                            transform: 'translate(0, -1.5px) scale(0.75)',
                          }
                        },
                        '.MuiFormControl-root': {
                          height: '100%',
                        }
                      }}
                    />
                  </div>

                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-[32px] max-w-full mq750:flex-wrap mq450:gap-[16px]">
                  <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
                    <TextField
                      id="standard-basic"
                      label="Email"
                      variant="standard"
                      className="w-full h-full"
                      InputProps={{ style: { height: '100%' } }}
                      InputLabelProps={{ style: { height: '100%' } }}
                      value={formValues.email}
                      sx={{
                        '.MuiInputBase-root': {
                          height: '100%',
                        },
                        '.MuiInputLabel-root': {
                          transform: 'translate(0, 1.5px) scale(1)',
                          '&.MuiInputLabel-shrink': {
                            transform: 'translate(0, -1.5px) scale(0.75)',
                          }
                        },
                        '.MuiFormControl-root': {
                          height: '100%',
                        }
                      }}
                    />
                  </div>
                  <div className="flex-1 flex flex-col items-end justify-start gap-[32px] min-w-[265px] max-w-full mq450:gap-[16px]">
                    <div className="self-stretch flex flex-row items-start justify-start max-w-full">
                      <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full mq450:h-auto">
                        <DateOfBirthField
                          value={dateOfBirth}
                          onChange={handleDateChange}
                        />
                      </div>

                    </div>
                    <Button
                      className="w-[185px] h-[52px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        color: "#fff",
                        fontSize: "18",
                        background: "#f28500",
                        borderRadius: "32px",
                        "&:hover": { background: "#f28500" },
                        width: 185,
                        height: 52,
                      }}
                      onClick={handleSaveChanges}
                      disabled={updateDisable}
                    >{updateConsumerProfile === "isLoading" ?
                      <Loader />
                      : "Save Changes"
                      }

                    </Button>
                  </div>
                </div>
              </form>}

          </div>
        </section>
      </main>
    </div>
  );
};

export default ConsumerProfile;
