import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../SubComponent/Loader";
import { cancelBookingConsumer, resetCancelBookingConsumer } from "../../Actions/Consumer/ConsumerBookingAction";
import { acceptBooking, resetAcceptBooking, rejectBooking, resetRejectBooking } from "../../Actions/Practitioner/PractitionerBookingAction";


const AppointmentCardPendingConsumer = ({ booking, onBookingUpdate }) => {
    const {
        fullName,
        serviceName,
        bookingStartTime,
        bookingEndTime,
        bookingType,
        bookingTag,
    } = booking;
    const dispatch = useDispatch();
    const BookingId = booking.id;
    const cancelStatus = useSelector((state) => state.cancelBookingConsumer.cancelBooking);
    const acceptStatus = useSelector((state) => state.cancelBooking.acceptBooking);
    const rejectStatus = useSelector((state) => state.cancelBooking.rejectBooking);

    const formattedStartDate = new Date(bookingStartTime).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC'
    });
    const formattedStartTime = new Date(bookingStartTime).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC'
    });
    const formattedEndTime = new Date(bookingEndTime).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC'
    });
    const startTime = new Date(bookingStartTime);
    const endTime = new Date(bookingEndTime);
    const durationInMilliseconds = endTime - startTime;
    const durationInMinutes = durationInMilliseconds / (1000 * 60);
    const handleCancel = () => {
        dispatch(rejectBooking(BookingId));
    };
    const handleAccept = () => {
        dispatch(acceptBooking(BookingId));
    };

    const handleReject = () => {
        dispatch(rejectBooking(BookingId));
    };
    useEffect(() => {
        if (acceptStatus.status === true || rejectStatus.status === true) {
            onBookingUpdate();
            dispatch(resetAcceptBooking());
            dispatch(resetRejectBooking());

        }
    }, [acceptStatus, rejectStatus, onBookingUpdate]);
    return (
        <div className="w-[350px] rounded-xl bg-color-gray-10 box-border flex flex-col items-end justify-center p-6 gap-[16px] min-w-[350px] max-w-[350px] shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                <div className="flex flex-row items-center justify-between w-full">
                    <div className="flex flex-row items-center gap-[6px]">
                        <img
                            className="w-6 h-6"
                            alt="Clock"
                            src="/vuesaxlinearclock-1.svg"
                        />
                        <div className="relative leading-[22px] font-semibold text-lg">{formattedStartTime}</div>
                        <div className="relative leading-[22px] font-semibold text-lg">{`TO `}</div>
                        <div className="relative leading-[22px] font-semibold text-lg">{formattedEndTime}</div>
                    </div>
                    {booking.bookingTag == 1 && (
                        <div
                            className={"flex relative leading-[24px] text-sm font-semibold justify-center items-center pt-[3px] pb-[3px] pl-[8px] pr-[8px] text-[#FA8C16] bg-otf "}
                        >
                            OTF
                        </div>
                    )}
                </div>

                <div className="flex-1 relative leading-[24px] text-sm">
                    {formattedStartDate}
                </div>

                <div className="self-stretch shadow-[0px_-1px_0px_#f0f0f0_inset] bg-color-gray-10 flex flex-row items-center justify-between pt-0 px-0 pb-4">
                    <img
                        className="w-12 relative rounded-[50%] h-12 object-cover"
                        alt="User"
                        src={booking.userImages ? booking.userImages.profileImage ? booking.userImages.profileImage : "/Placeholder-profile.png" : "/Placeholder-profile.png"}
                    />
                    <div className="flex-1 flex flex-row items-start justify-center gap-[8px] pl-4">
                        <div className="flex-1 flex flex-col items-center justify-center">
                            <div className="self-stretch relative leading-[30px] overflow-hidden whitespace-nowrap font-semibold">
                                {serviceName}
                            </div>
                            <div className="self-stretch relative text-base leading-[24px] overflow-hidden text-ellipsis whitespace-nowrap">
                                {fullName}
                            </div>
                        </div>
                    </div>

                    <div className={"flex relative leading-[24px] text-xs justify-center items-center pt-[3px] pb-[3px] pl-[8px] pr-[8px] rounded-xl text-white bg-pending"}>
                        Pending
                    </div>

                </div>

                <div className="self-stretch flex flex-row items-center justify-between text-sm">
                    <div className="flex flex-row items-center justify-start gap-[4px] text-m3-ref-neutral-neutral30">
                        <img
                            className="w-4 relative h-4 overflow-hidden shrink-0"
                            alt="Mode"
                            src="/timelapse.svg"
                        />
                        <div className="relative leading-[22px]">
                            {durationInMinutes + " min"}
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start gap-[4px] text-m3-ref-neutral-neutral30">
                        <img
                            className="w-4 relative h-4 overflow-hidden shrink-0"
                            alt="Mode"
                            src="/icons-1.svg"
                        />
                        <div className="relative leading-[22px]">
                            {bookingType === 0 ? "Online" : "On-Site"}
                        </div>
                    </div>
                </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-center text-center text-sm text-vendor-colors-monochromatic font-heading-h5">
                {bookingTag == 1 && (
                    <>
                        <div className="flex-1 relative leading-[20px] font-medium text-green-600 cursor-pointer"
                            onClick={() => handleAccept()}>
                            {"Accept"}

                        </div>
                        <img
                            className="self-stretch w-0 relative max-h-full object-contain"
                            alt=""
                            src="/line-7.svg"
                        />
                        <div className="flex-1 relative leading-[20px] font-medium text-red-600 cursor-pointer"
                            onClick={() => handleReject()}>
                            {"Reject"}
                        </div>
                    </>
                )}
                <img
                    className="self-stretch w-0 relative max-h-full object-contain"
                    alt=""
                    src="/line-8.svg"
                />
                {bookingTag !== 1 && (
                    <div className="flex-1 relative leading-[20px] font-medium text-red-600 cursor-pointer"
                        onClick={() => handleCancel()}>
                        {"Cancel Booking"}
                    </div>
                )}

            </div>
        </div>
    );
};

AppointmentCardPendingConsumer.propTypes = {
    booking: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        serviceName: PropTypes.string.isRequired,
        bookingStartTime: PropTypes.string.isRequired,
        bookingEndTime: PropTypes.string.isRequired,
        bookingType: PropTypes.number.isRequired,
    }).isRequired,

};

export default AppointmentCardPendingConsumer;