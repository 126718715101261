import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
  TextField,
  Button,
  IconButton,
  Icon,
  OutlinedInput,
} from "@mui/material";
import ConsumerHeader from "../components/Layout/ConsumerHeader";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../components/SubComponent/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState, useEffect } from "react";
import { getPractitioner } from "../Actions/Consumer/GetPractitionerAction";
import ServiceCard from "../components/SubComponent/ServiceCard";
import SimilarServiceCard from "../components/SubComponent/similarServiceCard";
import { getSchedules } from "../Actions/Practitioner/getSchedulesAction";
import format from "date-fns/format";
import {
  AddBooking,
  resetAddBooking,
} from "../Actions/Consumer/AddBookingAction";
import axios from "axios";
import { BASE_URL } from "../Constants";
import Tooltip from '@mui/material/Tooltip';


const generateTimeOptions = () => {
  const times = [];
  const start = new Date();
  start.setHours(0, 0, 0, 0);

  for (let i = 0; i <= 47; i++) {
    const time = new Date(start);
    time.setMinutes(start.getMinutes() + i * 30);
    times.push(time);
  }

  return times;
};

const timeOptions = generateTimeOptions();

const ServiceDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [service, setService] = useState(location.state.service);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [allImages, setAllImages] = useState([]);
  const scheduleNew = useSelector((state) => state.getSchedules.getSchedules);
  const schedule = scheduleNew !== "isLoading" && scheduleNew && scheduleNew.result && scheduleNew.result.length > 0
    ? scheduleNew.result
    : [];
  const bookingStatus = useSelector((state) => state.addBooking.addBooking);
  const practitioner = useSelector((state) => state.practitioner.practitioner);
  const [day, setDay] = useState(null);
  const [Availability, setAvailability] = useState([]);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [allow, setAllow] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("user"));
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [bookingType, setBookingType] = useState("");

  const handleNextImage = () => {
    if (
      service.userImages &&
      service.userImages.profileImage &&
      service.userImages &&
      service.userImages.images
    ) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % allImages.length);
    }
  };

  const findAvailableSlotsForDate = (targetDate) => {
    for (const entry of schedule) {
      for (const request of entry.updateScheduleSlotRequests) {
        let reqDay = new Date(request.slotDate).setHours(0, 0, 0, 0, 0);
        let sentDay = new Date(targetDate).setHours(0, 0, 0, 0, 0);
        if (reqDay === sentDay) {
          setSelectedSchedule(entry);
          return request.slotTimes.filter(slot =>
            slot.bookingStatus === "Open"
          );
        }
      }
    }
    return [];
  };
  useEffect(() => {
    if (bookingStatus.status === true) {
      setDay(null);
      setAvailability(null);
      setStart(null);
      setEnd(null);
      setSelectedAvailability(null);
      dispatch(resetAddBooking());
      setBookingType("");
    }
  }, [bookingStatus, service]);

  useEffect(() => {
    const body = {
      id: service.userId,
    };
    dispatch(getSchedules(body));
    dispatch(getPractitioner(service.userId));
    const profileImage =
      service.userImages && service.userImages.profileImage
        ? service.userImages.profileImage
        : "/Placeholder-images.png";
    const images =
      service.userImages && service.userImages.images
        ? service.userImages.images.split("|")
        : "/Placeholder-images.png";
    const allImagesArray = [profileImage, ...images];
    setAllImages(allImagesArray);
    if (UserData && UserData.user.role == 0) {
      {
        setAllow(true);
      }
    }
    if (bookingStatus.status === true) {
      setDay(null);
      setAvailability(null);
      setStart(null);
      setEnd(null);
      setSelectedAvailability(null);
      dispatch(resetAddBooking());
    }
  }, [service]);
  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const setSelectedDay = (date) => {
    setDay(date);
    const avail = findAvailableSlotsForDate(date);
    setAvailability(avail);
    setStart(null);
    setEnd(null);
  };

  const getAllScheduleDates = (schedule) => {
    let allDates = [];
    if (schedule != "isLoading" && schedule[0]) {
      schedule.forEach(({ scheduleStartDate, scheduleEndDate }) => {
        const dates = generateDateRange(scheduleStartDate, scheduleEndDate);
        allDates = [...allDates, ...dates];
      });
    }

    return allDates;
  };

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatDay = (date) => {
    const options = { weekday: "short" };
    return date.toLocaleDateString("en-US", options);
  };

  const allDates = getAllScheduleDates(schedule);
  const [currentPage, setCurrentPage] = useState(0);
  const datesPerPage = 7;

  const totalPages = Math.ceil(allDates.length / datesPerPage);

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSetAvailability = (slot) => {
    setSelectedAvailability(slot);
    setStart(null);
    setEnd(null);
  };
  const currentDates = allDates.slice(
    currentPage * datesPerPage,
    (currentPage + 1) * datesPerPage
  );

  const getFilteredEndTimes = (startTime) => {
    if (!startTime) return [];

    const startDate = new Date(startTime);
    const twoHoursAfterStart = new Date(
      startDate.getTime() + 2 * 60 * 60 * 1000
    );
    const parsedSlotEnd = parseTime(selectedAvailability.slotEnd);

    const effectiveEndTime =
      parsedSlotEnd < twoHoursAfterStart ? parsedSlotEnd : twoHoursAfterStart;

    return timeOptions.filter((time) => {
      const timeDate = new Date(time);
      const duration = (timeDate - startDate) / (1000 * 60);

      return duration >= 30 && timeDate <= effectiveEndTime;
    });
  };

  const parseTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0);
    return date;
  };

  const getFilteredStartTimes = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const currentTime = new Date();
    const filteredTimes = [];

    const parseTime = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, seconds, 0);
      return date;
    };

    if (selectedAvailability) {
      const slotStart = parseTime(selectedAvailability.slotStart);
      const slotEnd = parseTime(selectedAvailability.slotEnd);

      const slotEndMinus30 = new Date(slotEnd.getTime() - 30 * 60 * 1000);

      for (const time of timeOptions) {
        if (time >= slotStart && time <= slotEndMinus30) {
          filteredTimes.push(time);
        }
      }
    }
    return filteredTimes;
  };
  const handleSlotChange = (startTime) => {
    setStart(startTime);
  };
  const handleSlotChangeEnd = (endTime) => {
    setEnd(endTime);
  };
  const formatTime12h = (date) => format(date, "hh:mm a");

  const handleSave = () => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const selected = new Date(day);
    startTime.setFullYear(selected.getFullYear(), selected.getMonth(), selected.getDate());
    endTime.setFullYear(selected.getFullYear(), selected.getMonth(), selected.getDate());

    const timezoneOffset = new Date().getTimezoneOffset() * 60000;

    const adjustedStartTime = new Date(startTime.getTime() - timezoneOffset);
    const adjustedEndTime = new Date(endTime.getTime() - timezoneOffset);

    const body = {
      consumerId: UserData.user.id,
      practitionerId: practitioner.result[0].id,
      practitionerScheduleId: selectedSchedule.scheduleId,
      slotId: selectedAvailability.slotId,
      serviceId: service.id,
      bookingStartTime: adjustedStartTime,
      bookingEndTime: adjustedEndTime,
      bookingType: bookingType == "online" ? 0 : 1,
    };


    dispatch(AddBooking(body));
    handlesuccess();
  };

  const handlesuccess = () => { };
  const handleCardClick = (id) => {
    let srv;
    axios.get(BASE_URL + "Consumer/GetServiceDetails", {
      params: {
        serviceId: id
      }
    })
      .then(response => {
        srv = response.data.result[0];
        setService(srv);
      })
      .catch(error => {

        console.error('Error fetching service details:', error);
      });

  };
  const handleChatWithPractitioner = () => {
    if (service) {
      const practitionerDetails = {
        id: service.userId,
        img: service.userImages.profileImage ? service.userImages.profileImage : null,
        name: `${service.practitionerFirstName} ${service.practitionerLastName}`,
      };

      navigate("/consumer-profile", {
        state: {
          inbox: 2,
          otherUserDetail: practitionerDetails
        }
      });
    }
  };

  const tooltipTitle = !UserData
    ? "Please sign in to send a booking request"
    : "";
  const tooltipTitleChat = !UserData
    ? "Please sign in to chat with practitioner"
    : "";
  return (
    <div className="w-full relative bg-neutral-1 overflow-y-auto flex flex-col items-start justify-start pt-0 px-0 pb-10 box-border gap-[80px] tracking-[normal] leading-[normal] mq750:gap-[40px] mq450:gap-[20px]">
      <ConsumerHeader></ConsumerHeader>
      {practitioner === "isLoading" ? (
        <div className="flex items-center justify-center min-h-full min-w-full">
          <Loader />
        </div>
      ) : (
        practitioner.status === true && (
          <main className="self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-10 box-border max-w-full">
            <section className="w-[1200px] flex flex-row items-start justify-center gap-[56px] max-w-full text-left text-lg text-m3-black font-web-primary-h3-primary-dm-sans mq1050:flex-wrap mq750:gap-[28px]">
              <div className="flex-1 flex flex-col items-start justify-start gap-[40px] min-w-[426px] max-w-full mq750:gap-[20px] mq750:min-w-full">
                <div className="self-stretch flex flex-col items-start justify-start gap-[24px] max-w-full text-5xl">
                  <div className="self-stretch flex flex-row items-start justify-start gap-[7px] max-w-full mq750:flex-wrap">
                    <div className="flex flex-col items-start justify-start py-0 pr-[17px] pl-0">
                      <img
                        className="h-20 w-20 relative rounded-[50%] object-cover min-h-[80px]"
                        loading="lazy"
                        alt=""
                        src={
                          service.userImages && service.userImages.profileImage
                            ? service.userImages.profileImage
                            : "/Placeholder-images.png"
                        }
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start pt-[22px] px-0 pb-0">
                      <div className="flex flex-row items-start justify-start gap-[8px]">
                        <h1 className="m-0 relative text-inherit leading-[36px] font-bold font-inherit mq450:text-lgi mq450:leading-[29px]">
                          {service.practitionerFirstName +
                            " " +
                            service.practitionerLastName}
                        </h1>
                        <div className="h-[26px] flex flex-col items-start justify-start pt-2.5 px-0 pb-0 box-border">
                          <div className="w-px h-[17px] relative box-border border-r-[1px] border-solid border-m3-black" />
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col items-start justify-start pt-7 px-0 pb-0 box-border min-w-[231px] max-w-full text-base">
                      <div className="self-stretch relative leading-[24px]">
                        {practitioner.result[0].bussinessDetail &&
                          practitioner.result[0].bussinessDetail.address
                          ? practitioner.result[0].bussinessDetail.address
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch rounded-3xl overflow-hidden flex flex-row items-start justify-start max-w-full">
                    <div className="h-[447px] flex-1 relative max-w-full">
                      <img
                        className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                        alt="Practitioner"
                        src={
                          allImages.length > 0
                            ? allImages[currentIndex]
                            : "/Placeholder-profile.png"
                        }
                      />
                      <img
                        className="absolute top-[calc(50%_-_20.05px)] right-[32px] rounded-lg w-10 h-10 object-contain z-[1]"
                        loading="lazy"
                        alt="Next"
                        src="/button@2x.png"
                        onClick={handleNextImage}
                      />
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start max-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full">
                    <h3 className="m-0 relative text-inherit leading-[28px] font-bold font-inherit">
                      About the Service
                    </h3>
                    <div className="self-stretch flex flex-row items-start justify-start max-w-full text-base">
                      <div className="flex-1 relative leading-[24px] [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:4] [-webkit-box-orient:vertical] max-w-full">
                        {service.serviceDescription}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start relative gap-[24px] max-w-full">
                  <h3 className="m-0 relative text-inherit leading-[28px] font-bold font-inherit inline-block min-w-[82px]">
                    Location
                  </h3>
                  <img
                    className="self-stretch h-[284px] relative max-w-full overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/basemap-image@2x.png"
                  />
                  <img
                    className="w-[52px] h-[52px] absolute !m-[0] top-[calc(50%_-_26.1px)] left-[calc(50%_-_26px)] overflow-hidden shrink-0 z-[1]"
                    loading="lazy"
                    alt=""
                    src="/location-marker.svg"
                  />
                  <div className="self-stretch flex flex-col items-start justify-start gap-[8px] max-w-full text-base">
                    <div className="self-stretch flex flex-row items-start justify-start p-2 box-border gap-[16px] max-w-full mq750:flex-wrap">
                      <div className="bg-hitbox overflow-hidden flex flex-col items-center justify-center">
                        <img
                          className="w-6 h-6 relative overflow-hidden shrink-0"
                          loading="lazy"
                          alt=""
                          src="/directions.svg"
                        />
                      </div>
                      <div className="flex-1 relative leading-[24px] inline-block min-w-[228px] max-w-full">
                      {practitioner && practitioner.result[0] && practitioner.result[0].bussinessDetail && practitioner.result[0].bussinessDetail.address
        ? practitioner.result[0].bussinessDetail.address
        : "N/A"}
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start p-2 box-border gap-[16px] max-w-full mq750:flex-wrap">
                      <div className="bg-hitbox overflow-hidden flex flex-col items-center justify-center">
                        <img
                          className="w-6 h-6 relative overflow-hidden shrink-0"
                          loading="lazy"
                          alt=""
                          src="/phone.svg"
                        />
                      </div>
                      <div className="flex-1 relative leading-[24px] inline-block min-w-[81px] whitespace-nowrap max-w-full">
                        {practitioner.result[0].phoneNumber
                          ? practitioner.result[0].phoneNumber
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[24px] max-w-[200px]">
                  <h3 className="m-0 relative text-inherit leading-[28px] font-bold font-inherit">
                    Similar Services
                  </h3>
                  <div className="flex-1 flex flex-col items-start justify-start gap-[24px] text-5xl text-neutral-11 mq750:max-w-full cursor-pointer">
                    {practitioner &&
                      practitioner.result[0].service
                        .filter((srv) => srv.isActive)
                        .map((srv) => (
                          <div className="cursor-pointer rounded-xl" onClick={() => handleCardClick(srv.id)}>
                            <SimilarServiceCard
                              key={srv.id}
                              data={srv}
                              practitioner={practitioner.result[0]}
                            />
                          </div>
                        ))}
                  </div>
                </div>
              </div>
              <div className="w-[488px] flex flex-col items-start justify-start pt-[4.5px] px-0 pb-0 box-border min-w-[488px] max-w-full text-base text-gray-1000 mq1050:flex-1 mq750:min-w-full">
                <div className="self-stretch flex flex-col items-start justify-start gap-[24px] max-w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                    <div className="relative leading-[24px] font-semibold"></div>
                    <div className="self-stretch flex flex-row items-start justify-between gap-[20px] text-13xl mq450:flex-wrap">
                      <div className="flex flex-col items-start justify-start gap-[4px]">
                        <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
                          {service.serviceName}
                        </h1>
                        <div className="relative text-sm leading-[22px] font-semibold inline-block min-w-[44px]">
                          {service.type === 0
                            ? "Online"
                            : service.type === 1
                              ? "Onsite"
                              : "Online and Onsite"}
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start pt-[19px] px-0 pb-0 text-5xl text-neutral-11">
                        <b className="relative leading-[36px] inline-block min-w-[46px] whitespace-nowrap mq450:text-lgi mq450:leading-[29px]">
                          {"$" + service.cost}
                        </b>
                      </div>
                    </div>
                    {schedule != "isLoading" && schedule[0] && (
                      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] ">
                        <div className="self-stretch flex flex-row items-center justify-between py-1 px-0 gap-[20px] mq450:flex-wrap">
                          <div className="w-32 relative leading-[12px] font-semibold flex items-center shrink-0">
                            Date
                          </div>
                          <div className="flex flex-row items-start justify-start gap-[7px]">
                            <IconButton
                              className="self-stretch w-[35.2px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] min-h-[20px]"
                              sx={{ borderRadius: "0px 0px 0px 0px" }}
                              color="primary"
                              onClick={handlePrevious}
                              disabled={currentPage === 0}
                            >
                              <Icon>arrow_back_sharp</Icon>
                            </IconButton>
                            <IconButton
                              className="self-stretch w-[35.2px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] min-h-[20px]"
                              sx={{ borderRadius: "0px 0px 0px 0px" }}
                              color="primary"
                              onClick={handleNext}
                              disabled={currentPage >= totalPages - 1}
                            >
                              <Icon>arrow_forward_sharp</Icon>
                            </IconButton>
                          </div>
                        </div>
                        <div className="self-stretch flex flex-row items-start justify-center py-0 pl-0 gap-[8px] text-center text-xs mq750:pr-[31px] mq750:box-border mq450:flex-wrap">
                          {currentDates.map((date) => {

                            const today = new Date();
                            today.setHours(0, 0, 0, 0);


                            const isPastDate = new Date(date) < today;

                            return (
                              <div
                                key={date}
                                onClick={
                                  !isPastDate
                                    ? () => setSelectedDay(date)
                                    : undefined
                                }
                                className={`flex-1 rounded ${day &&
                                  new Date(day).getTime() ===
                                  new Date(date).getTime()
                                  ? "bg-[#F28500] "
                                  : "bg-aliceblue"
                                  } flex flex-col items-center justify-start pt-2 px-2 pb-1 box-border gap-[2px] min-w-[60px] max-w-[62px] cursor-pointer ${isPastDate
                                    ? "cursor-not-allowed opacity-50"
                                    : ""
                                  }`}
                                style={{
                                  color:
                                    day &&
                                      new Date(day).getTime() ===
                                      new Date(date).getTime()
                                      ? "#FFFFFF"
                                      : "#000000",
                                }}
                              >
                                <b className="relative leading-[18px] uppercase inline-block min-w-[38px]">
                                  {formatDate(date)}
                                </b>
                                <div className="self-stretch relative leading-[18px]">
                                  {formatDay(date)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  {schedule != "isLoading" && schedule[0] && (
                    <div className="self-stretch flex flex-row flex-wrap items-start justify-start pt-0 px-0 pb-1 box-border max-w-full">
                      <div className="flex-1 flex flex-col items-start justify-start gap-[16px] max-w-full">
                        <div className="w-[342px] flex flex-row items-center justify-start py-1 px-0 box-border max-w-full">
                          <div className="w-32 relative leading-[12px] font-semibold flex items-center shrink-0">
                            Availability
                          </div>
                        </div>
                        {day && (
                          <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px]">
                            {Availability.map((slot) => (
                              <button
                                key={slot.slotId}
                                className={`cursor-pointer py-0.5 px-[3px] rounded overflow-hidden flex flex-row items-center justify-start border-[1px] border-solid ${selectedAvailability &&
                                  slot.slotId === selectedAvailability.slotId
                                  ? "bg-darkorange border-darkorange"
                                  : "bg-transparent border-darkorange"
                                  }`}
                                onClick={() => handleSetAvailability(slot)}
                              >
                                <div className="rounded-81xl bg-primary-dark hidden flex-col items-center justify-center relative">
                                  <div className="w-2 h-2 absolute !m-[0] right-[-2px] bottom-[-2px] rounded-45xl bg-neutral-1 hidden z-[0]">
                                    <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl bg-success-main overflow-hidden flex flex-row items-start justify-start">
                                      <div className="h-2 w-2 relative rounded-81xl" />
                                    </div>
                                  </div>
                                  <div className="w-6 h-6 relative [transform:_rotate(-90deg)] z-[2]" />
                                </div>
                                <b
                                  className={`relative text-xs leading-[18px] uppercase font-web-primary-h3-primary-dm-sans text-left whitespace-nowrap ${selectedAvailability &&
                                    slot.slotId === selectedAvailability.slotId
                                    ? "text-white"
                                    : "text-darkorange"
                                    }`}
                                >
                                  {new Date(
                                    `2000-01-01T${slot.slotStart}`
                                  ).toLocaleTimeString([], {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })}{" "}
                                  -{" "}
                                  {new Date(
                                    `2000-01-01T${slot.slotEnd}`
                                  ).toLocaleTimeString([], {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  })}
                                </b>
                                <img
                                  className="h-6 w-6 relative rounded-81xl overflow-hidden shrink-0 hidden min-h-[24px]"
                                  alt=""
                                  src="/cancelfilled1.svg"
                                />
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {!UserData || UserData.user.role === 0 ? (
                    <>
                      {day && (
                        <div className="self-stretch flex flex-row items-start justify-start gap-[20px] text-base mq450:flex-wrap">
                          <div className="flex flex-row items-center justify-start gap-[8px]">
                            <input
                              className="accent-highlight-darkest m-0 h-6 w-6 relative rounded-md box-border min-h-[24px] border-[1.5px] border-solid border-highlight-darkest"
                              type="radio"
                              name="bookingType"
                              value="online"
                              disabled={service.type != 0 && service.type != 2}
                              checked={bookingType === "online"}
                              onChange={() => setBookingType("online")}
                            />
                            <div className="relative leading-[24px] inline-block min-w-[48px]">
                              Online
                            </div>
                          </div>
                          <div className="flex flex-row items-center justify-start gap-[8px]">
                            <input
                              className="accent-highlight-darkest m-0 h-6 w-6 relative rounded-md box-border min-h-[24px] border-[1.5px] border-solid border-highlight-darkest"
                              type="radio"
                              name="bookingType"
                              value="offline"
                              disabled={service.type != 1 && service.type != 2}
                              checked={bookingType === "offline"}
                              onChange={() => setBookingType("offline")}
                            />
                            <div className="relative leading-[24px] inline-block min-w-[58px]">
                              On-site
                            </div>
                          </div>
                        </div>
                      )}
                      {schedule != "isLoading" && schedule[0] ? (
                        <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
                          <div className="w-32 h-3 relative leading-[24px] font-semibold flex items-center shrink-0">
                            Timings
                          </div>
                          {selectedAvailability && (
                            <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 pr-1 pl-0 gap-[24px_20px] text-xs text-text-secondary">
                              <div className="flex-1 flex flex-row items-start justify-start min-w-[93px]">
                                <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px]">
                                  <div className="relative leading-[18px] inline-block min-w-[58px]">
                                    Start Time
                                  </div>
                                  <FormControl
                                    className="self-stretch h-6 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200"
                                    variant="standard"
                                    sx={{
                                      borderTopWidth: "1px",
                                      borderRightWidth: "1px",
                                      borderBottomWidth: "1px",
                                      borderLeftWidth: "1px",
                                      borderRadius: "0px 0px 0px 0px",
                                      width: "100%",
                                      height: "24px",
                                      m: 0,
                                      p: 0,
                                      "& .MuiInputBase-root": {
                                        m: 0,
                                        p: 0,
                                        minHeight: "24px",
                                        justifyContent: "center",
                                        display: "inline-flex",
                                      },
                                      "& .MuiInputLabel-root": {
                                        m: 0,
                                        p: 0,
                                        minHeight: "24px",
                                        display: "inline-flex",
                                      },
                                      "& .MuiMenuItem-root": {
                                        m: 0,
                                        p: 0,
                                        height: "24px",
                                        display: "inline-flex",
                                      },
                                      "& .MuiSelect-select": {
                                        m: 0,
                                        p: 0,
                                        height: "24px",
                                        alignItems: "center",
                                        display: "inline-flex",
                                      },
                                      "& .MuiInput-input": { m: 0, p: 0 },
                                      "& .MuiInputBase-input": {
                                        color: "#322f35",
                                        fontSize: 16,
                                        fontWeight: "Regular",
                                        fontFamily: "DM Sans",
                                        textAlign: "left",
                                        p: "0 !important",
                                      },
                                    }}
                                  >
                                    <InputLabel color="primary" />
                                    <Select
                                      color="primary"
                                      disableUnderline
                                      value={start ? start : ""}
                                      onChange={(event) =>
                                        handleSlotChange(event.target.value)
                                      }
                                      IconComponent={() => (
                                        <img
                                          width="24px"
                                          height="24px"
                                          src="/arrow-drop-down.svg"
                                          style={{}}
                                        />
                                      )}
                                    >
                                      {getFilteredStartTimes().map((time) => (
                                        <MenuItem key={time} value={time}>
                                          {formatTime12h(time)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <FormHelperText />
                                  </FormControl>
                                  <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-input-standard-enabledborder" />
                                </div>
                                <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border font-h5-medium">
                                  <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                                    Helper text
                                  </div>
                                </div>
                              </div>
                              <div className="flex-1 flex flex-row items-start justify-start min-w-[93px]">
                                <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px]">
                                  <div className="relative leading-[18px] inline-block min-w-[52px]">
                                    End Time
                                  </div>
                                  <FormControl
                                    className="self-stretch h-6 font-web-primary-h3-primary-dm-sans text-base text-m3-ref-neutral-neutral20-200"
                                    variant="standard"
                                    sx={{
                                      borderTopWidth: "1px",
                                      borderRightWidth: "1px",
                                      borderBottomWidth: "1px",
                                      borderLeftWidth: "1px",
                                      borderRadius: "0px 0px 0px 0px",
                                      width: "100%",
                                      height: "24px",
                                      m: 0,
                                      p: 0,
                                      "& .MuiInputBase-root": {
                                        m: 0,
                                        p: 0,
                                        minHeight: "24px",
                                        justifyContent: "center",
                                        display: "inline-flex",
                                      },
                                      "& .MuiInputLabel-root": {
                                        m: 0,
                                        p: 0,
                                        minHeight: "24px",
                                        display: "inline-flex",
                                      },
                                      "& .MuiMenuItem-root": {
                                        m: 0,
                                        p: 0,
                                        height: "24px",
                                        display: "inline-flex",
                                      },
                                      "& .MuiSelect-select": {
                                        m: 0,
                                        p: 0,
                                        height: "24px",
                                        alignItems: "center",
                                        display: "inline-flex",
                                      },
                                      "& .MuiInput-input": { m: 0, p: 0 },
                                      "& .MuiInputBase-input": {
                                        color: "#322f35",
                                        fontSize: 16,
                                        fontWeight: "Regular",
                                        fontFamily: "DM Sans",
                                        textAlign: "left",
                                        p: "0 !important",
                                      },
                                    }}
                                  >
                                    <InputLabel color="primary" />
                                    <Select
                                      color="primary"
                                      disableUnderline
                                      value={end ? end : ""}
                                      onChange={(event) =>
                                        handleSlotChangeEnd(event.target.value)
                                      }
                                      IconComponent={() => (
                                        <img
                                          width="24px"
                                          height="24px"
                                          src="/arrow-drop-down-1.svg"
                                          style={{}}
                                        />
                                      )}
                                    >
                                      {getFilteredEndTimes(start).map((time) => (
                                        <MenuItem key={time} value={time}>
                                          {formatTime12h(time)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <FormHelperText />
                                  </FormControl>
                                  <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-input-standard-enabledborder" />
                                </div>
                                <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border font-h5-medium">
                                  <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                                    Helper text
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <h4>No scheduled slots available for this service</h4>
                      )}
                      <Tooltip title={tooltipTitle} arrow>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                          <Button
                            className="self-stretch h-[52px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] mq450:pl-5 mq450:pr-5 mq450:box-border"
                            variant="contained"
                            disabled={
                              schedule.errorCode == "Status404NotFound" ||
                              !allow ||
                              !start ||
                              !end ||
                              bookingType == ""
                            }
                            sx={{
                              textTransform: "none",
                              color: "#fff",
                              fontSize: "18",
                              background: "#f28500",
                              borderRadius: "32px",
                              width: "100%",
                              "&:hover": { background: "#f28500", cursor: "pointer" },
                              cursor: "pointer",
                              height: 52,

                            }}
                            onClick={handleSave}
                          >
                            {bookingStatus === "isLoading" ? (
                              <div className="mt-2">
                                <Loader />
                              </div>
                            ) : (
                              "Send Booking Request"
                            )}
                          </Button>
                        </div>
                      </Tooltip>

                      <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full text-center text-xs text-m3-black">
                        <Tooltip title={tooltipTitleChat} arrow>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Button
                              className="self-stretch h-[52px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)] mq450:pl-5 mq450:pr-5 mq450:box-border"
                              variant="contained"
                              disabled={!allow}
                              onClick={handleChatWithPractitioner}
                              sx={{
                                textTransform: "none",
                                color: "#262626",
                                fontSize: "18",
                                background: "#F2F2F2",
                                width: "100%",
                                border: "none",
                                boxShadow: "0 0 5px rgba(22, 22, 22, 0.8)",
                                borderRadius: "32px",
                                "&:hover": { background: "#fff", cursor: "pointer" },
                                cursor: "pointer",
                                height: 52,
                              }}
                            >
                              Chat With Practioner
                            </Button>
                          </div>
                        </Tooltip>
                        <div className="relative leading-[18px] inline-block max-w-full">
                          If you can’t find your desired slot feel free to reach out
                          us via chat.
                        </div>
                      </div>

                    </>
                  )

                    : <div>You are currently signed in as a practitioner, please sign in as a consumer to continue</div>
                  }
                </div>
              </div>
            </section>
          </main>
        )
      )}
      <footer className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-mid text-gray-200 font-web-primary-h3-primary-dm-sans">
        <div className="w-[1200px] flex flex-col items-start justify-start gap-[32px] max-w-full mq750:gap-[16px]">
          <div className="flex flex-row items-start justify-start gap-[54.9px] lg:flex-wrap mq750:gap-[27px]">
            <div className="w-[248px] flex flex-col items-start justify-start gap-[32px] text-mini text-gray-700">
              <div className="w-[200px] flex flex-row items-end justify-start gap-[10.5px]">
                <img
                  className="h-10 w-[40.2px] relative overflow-hidden shrink-0"
                  alt=""
                  src="/the-healers-logo-12.svg"
                />
                <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[10.4px]">
                  <img
                    className="self-stretch h-[19.1px] relative max-w-full overflow-hidden shrink-0"
                    alt=""
                    src="/the-healers-11.svg"
                  />
                </div>
              </div>
              <div className="self-stretch h-[104px] relative tracking-[-0.1px] leading-[26px] inline-block mix-blend-normal">
                Your all-in-one platform for effortless wellness, connecting you
                with curated fitness, wellness services and personalized
                recommendations.
              </div>
            </div>
            <div className="w-[186.8px] flex flex-col items-start justify-start gap-[16px]">
              <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                Company
              </div>
              <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.2px] font-normal font-inherit">
                About us
              </h3>
              <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.2px] font-normal font-inherit">
                Contact us
              </h3>
              <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.2px] font-normal font-inherit">
                Privacy Policy
              </h3>
              <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.2px] font-normal font-inherit">
                Terms and Conditions
              </h3>
            </div>
            <div className="flex flex-col items-start justify-start gap-[16px]">
              <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                Services
              </div>
              <h3 className="m-0 w-[186.8px] relative text-inherit tracking-[-0.2px] font-normal font-inherit inline-block">
                Mind
              </h3>
              <h3 className="m-0 w-[186.8px] relative text-inherit tracking-[-0.2px] font-normal font-inherit inline-block">
                Body
              </h3>
              <h3 className="m-0 w-[186.8px] relative text-inherit tracking-[-0.2px] font-normal font-inherit inline-block">
                Medicine
              </h3>
              <h3 className="m-0 relative text-inherit tracking-[-0.2px] leading-[22px] font-normal font-inherit">
                Psychologists and Counselors
              </h3>
            </div>
            <div className="w-[186.8px] flex flex-col items-start justify-start gap-[16px]">
              <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                Links
              </div>
              <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.2px] font-normal font-inherit">
                Sitemap
              </h3>
              <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.2px] font-normal font-inherit">
                Security
              </h3>
              <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.2px] font-normal font-inherit">
                Support Center
              </h3>
              <h3 className="m-0 self-stretch relative text-inherit tracking-[-0.2px] font-normal font-inherit">
                Cookie Preferences
              </h3>
            </div>
            <div className="w-[135.9px] flex flex-col items-start justify-start gap-[16px] text-4xs-4 text-neutral-1 font-open-sans">
              <img
                className="self-stretch h-10 relative max-w-full overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/app-store--filled1.svg"
              />
              <div className="self-stretch flex flex-row items-start justify-start">
                <div className="flex-1 flex flex-row items-end justify-start pt-[4.6px] pb-1.5 pr-[9.2px] pl-2.5 relative gap-[19.8px]">
                  <img
                    className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/vector.svg"
                  />
                  <img
                    className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full z-[1]"
                    alt=""
                    src="/vector-1.svg"
                  />
                  <div className="flex flex-col items-start justify-end pt-0 px-0 pb-[3.2px]">
                    <img
                      className="w-[11.1px] h-[21.5px] relative z-[4]"
                      alt=""
                      src="/vector-5.svg"
                    />
                  </div>
                  <img
                    className="h-[13.4px] w-[16.2px] absolute !m-[0] bottom-[7.2px] left-[10.1px] z-[2]"
                    alt=""
                    src="/vector-2.svg"
                  />
                  <img
                    className="h-[11px] w-[12.5px] absolute !m-[0] top-[calc(50%_-_5.5px)] left-[20.5px] z-[3]"
                    alt=""
                    src="/vector-4.svg"
                  />
                  <div className="flex-1 flex flex-col items-start justify-start gap-[1.4px]">
                    <div className="relative inline-block [-webkit-text-stroke:0.2px_#fff] min-w-[40px] z-[2]">
                      GET IT ON
                    </div>
                    <img
                      className="self-stretch h-[17px] relative max-w-full overflow-hidden shrink-0 z-[2]"
                      alt=""
                      src="/vector-3.svg"
                    />
                  </div>
                  <img
                    className="h-[12.9px] w-[16.3px] absolute !m-[0] top-[7.1px] left-[10px] z-[5]"
                    alt=""
                    src="/vector-6.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch h-[58px] overflow-hidden shrink-0 flex flex-col items-start justify-start pt-[0.5px] px-0 pb-[55.5px] box-border gap-[29.5px] text-mini mq750:h-auto">
            <img
              className="self-stretch relative max-w-full overflow-hidden max-h-full shrink-0 [debug_commit:1de1738]"
              loading="lazy"
              alt=""
              src="/line-copy.svg"
            />
            <img
              className="w-[15.1px] h-[13px] relative hidden"
              alt=""
              src="/heart2.svg"
            />
            <div className="self-stretch flex flex-row items-start justify-between shrink-0 [debug_commit:1de1738] gap-[20px] mq750:flex-wrap">
              <div className="w-[235.3px] relative tracking-[-0.1px] leading-[52px] inline-block shrink-0 [debug_commit:1de1738]">
                © 2024 Copyright, All Right Reserved.
              </div>
              <div className="w-48 flex flex-col items-start justify-start pt-[2.1px] px-0 pb-0 box-border">
                <div className="self-stretch flex flex-row items-start justify-between shrink-0 [debug_commit:1de1738] gap-[20px]">
                  <img
                    className="h-6 w-6 relative min-h-[24px]"
                    loading="lazy"
                    alt=""
                    src="/buttontwitter.svg"
                  />
                  <img
                    className="h-6 w-6 relative min-h-[24px]"
                    loading="lazy"
                    alt=""
                    src="/buttonfacebook.svg"
                  />
                  <img
                    className="h-6 w-6 relative object-cover min-h-[24px]"
                    alt=""
                    src="/logoinstagram@2x.png"
                  />
                  <img
                    className="h-6 w-6 relative object-cover min-h-[24px]"
                    alt=""
                    src="/logolinkedin@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ServiceDetails;
