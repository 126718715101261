import React, { useEffect, useState } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
  TextField,
  Icon,
  IconButton,
  Autocomplete,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Button as BaseButton, buttonClasses } from '@mui/base/Button';
import Component from "../components/Layout/Component";
import ConsumerHeader from "../components/Layout/ConsumerHeader";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../Actions/Catagories/GetCategoriesAction";
import { getFeaturedPractitioners } from "../Actions/Consumer/GetFeaturedPractitonerAction";
import CMPBusinessCard from "../components/SubComponent/ButtonBusinessCard";
import Loader from "../components/SubComponent/Loader"
import { Navigate, useNavigate } from "react-router-dom";
import { getElasticPractitioners, resetElasticPractitioners } from "../Actions/Consumer/ElasticPractitionersAction";
import { useLocation } from 'react-router-dom';
//
import { getToken, getMassaging } from "firebase/messaging";
import { messaging } from "../firebase";
import userService, * as UserService from "../services/user.service";

const SearchResultsPractitioners = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const allCategories = useSelector((state) => state.getCategories.getAll);
  const allPractitioners = useSelector((state) => state.getPractitioners.getPractitioners);
  const dispatch = useDispatch();
  const [selectedDistance, setSelectedDistance] = useState("");
  const UserData = JSON.parse(localStorage.getItem("user"));
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(location.state ? location.state.searchText : null);
  const [displayPractitioners, setDisplayPractitioners] = useState([]);
  const filtered = useSelector((state) => state.elasticPractitioners.elasticPractitioners);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const pageSize = 1000000000;
  useEffect(() => {
    dispatch(getAll());
    dispatch(getFeaturedPractitioners(pageNumber, pageSize));
    HandleSearch();
  }, []);
  const handleCategoryChange = (categoryId) => {
    setSelectedCategories((prevSelectedCategories) =>
      prevSelectedCategories.includes(categoryId)
        ? prevSelectedCategories.filter((id) => id !== categoryId)
        : [...prevSelectedCategories, categoryId]
    );
  };
  const handleDistanceChange = (event) => {
    setSelectedDistance(event.target.value);
  };
  const resetToInitialState = () => {
    setSelectedCategories([]);
    setSelectedDistance("");
    setSearchText("");
    dispatch(resetElasticPractitioners());
    setCurrentPage(1);
  };
  const handleCardClick = (id) => {
    navigate('/practitioner-details', { state: { id } });
  };
  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const HandleSearch = () => {
    const searchVal = searchText;
    const categoryIds = selectedCategories;
    if (searchText !== null && searchText.length > 0) {
      dispatch(getElasticPractitioners(searchVal, categoryIds));
    }

    setCurrentPage(1);
  };
  const HandleSearchApply = () => {
    const searchVal = searchText;
    const categoryIds = selectedCategories;
    dispatch(getElasticPractitioners(searchVal, categoryIds));
    setCurrentPage(1);
  };
  useEffect(() => {
    if (filtered && filtered.results) {
      setDisplayPractitioners(filtered.results);
    }
    else if (filtered !== "isLoading" && !filtered.results && allPractitioners.length != 0) {
      setDisplayPractitioners(allPractitioners);
    }
  }, [allPractitioners, filtered]);
  const totalPages = Math.ceil(displayPractitioners.length / itemsPerPage);
  const currentPractitioners = displayPractitioners.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handlePageChange = (newPage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // fcm work
  const [fcmPosted, setFcmPosted] = useState(localStorage.getItem('fcmPosted') === 'true');

  const user = JSON.parse(localStorage.getItem("user"));
  // console.log(user, "user in main.jsx");
  const employeeId =
    user && user.user !== undefined && user.user != null ? user.user.id : false;
  // fcm and device id
  const [fcm, setFcm] = useState("");
  const [deviceId, setDeviceId] = useState("");

  // useEffect(() => {
  //   requestFCMPermission();
  // }, []);
  useEffect(() => {
    if (!fcmPosted) {
      requestFCMPermission();
    }
  }, [fcmPosted]);

  useEffect(() => {
    postFCMToken();
  }, [deviceId, fcm]);

  async function requestFCMPermission() {
    try {
      // Geting FCM token and device id
      requestPermission();
      getDeviceID();

      // Saving token and device ID to fcmBody
      const fcmBody = {
        id: employeeId,
        fcmId: fcm,
        deviceId: deviceId,
      };
    } catch (error) {
      console.error("Error requesting FCM permission:", error);
    }
  }

  async function postFCMToken() {
    if (fcm !== "" && deviceId !== "" && employeeId !== false) {
      const fcmBody = {
        id: employeeId,
        fcmId: fcm,
        deviceId: deviceId,
      };
      localStorage.setItem('deviceId', JSON.stringify(deviceId));

      await userService.addFcmToken(fcmBody);
      setFcm("");
      setDeviceId("");
      setFcmPosted(true);
      localStorage.setItem('fcmPosted', true);
    }
  }
  function getDeviceID() {
    if (navigator && navigator.userAgent) {
      setDeviceId(navigator.userAgent);
      return navigator.userAgent;
    } else {
      setDeviceId("default_device_id");
      return "default_device_id";
    }
  }

  async function requestPermission() {
    const currentPermission = Notification.permission;

    // Check if the permission has already been granted or denied
    if (currentPermission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BFOXm1XAOLFvgw7dHWEn8Hj8ZRiru-Y0g44AqRBtQ1fuWfLG6lCVJJUQ4oNeL3jQRXA1I4OZM6AR2h9B8yXQQmk",
      });

      setFcm(token);
      return token;
    } else if (currentPermission === "denied") {

      return null; // Permission denied
    }

    // If permission hasn't been granted or denied, proceed with the request
    const requestedPermission = await Notification.requestPermission();

    if (requestedPermission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey:
            "BFOXm1XAOLFvgw7dHWEn8Hj8ZRiru-Y0g44AqRBtQ1fuWfLG6lCVJJUQ4oNeL3jQRXA1I4OZM6AR2h9B8yXQQmk",
        });

        setFcm(token);
        return token;
      } catch (error) {
        console.error("Error obtaining token:", error.message);
        throw error;
      }
    } else if (requestedPermission === "denied") {

      return null;
    }
  }
  useEffect(() => {
    if (selectedCategories.length === 0 && !searchText) {
      dispatch(resetElasticPractitioners());
    }

  }, [selectedCategories, searchText]);
  console.log("fcm:", fcm, "deviceID:", deviceId);
  // fcm end
  return (
    <div className="w-full relative bg-neutral-1 overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-10 box-border gap-[40px] leading-[normal] tracking-[normal] text-left text-13xl text-text-primary font-web-primary-h3-primary-dm-sans mq750:gap-[20px]">
      <ConsumerHeader></ConsumerHeader>
      <div className="self-stretch rounded-xl bg-neutral-1 overflow-hidden flex flex-col items-center justify-start py-6 px-5 box-border max-w-full">
        <form className="m-0 w-[1200px] overflow-hidden flex flex-row flex-wrap items-center justify-center gap-[24px] max-w-full"
          onSubmit={(e) => {
            e.preventDefault();
            HandleSearch();
          }}>
          <div className="flex-1 flex flex-row items-center justify-start gap-[16px] min-w-[500px] max-w-[500px] mq750:min-w-full mq1050:flex-wrap">
            <TextField
              className="[border:none] bg-[transparent] h-[50px] w-[642px] font-web-primary-h3-primary-dm-sans text-base text-m3-black max-w-full"
              color="primary"
              size="small"
              placeholder="Search for anything..."
              variant="outlined"
              value={searchText}
              onChange={handleSearchTextChange}
              sx={{
                "& .MuiInputBase-root": { height: "50px" },
                width: "calc(100% - 121px)",
              }}
            />

            {/* <TextField
              color="primary"
              variant="outlined"
              placeholder="Location"
              helperText=""
              sx={{
                "& .MuiInputBase-root": { height: "50px" },
                width: "calc(100% - 121px)",
              }}
            /> */}
          </div>

          <Button
            className="h-[50px] w-[121px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "18",
              background: "#f28500",
              border: "#f28500 solid 1px",
              borderRadius: "8px",
              "&:hover": { background: "#f28500" },
            }}
            onClick={HandleSearch}
          >
            Search
          </Button>
        </form>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2 box-border max-w-full">
        <div className="flex-1 flex flex-row items-start justify-center py-0 px-5 box-border [row-gap:20px] max-w-full lg:flex-wrap lg:justify-start">
          <div className="h-9 w-4 flex flex-col items-start justify-start pt-7 px-0 pb-0 box-border">
            <div className="w-4 h-2 hidden flex-col items-start justify-start">
              <div className="w-px h-2 relative hidden" />
            </div>
          </div>
          <div className="w-[868px] flex flex-col items-start justify-start pt-2 px-0 pb-0 box-border max-w-full">
            <h1 className="m-0 self-stretch relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
              Showing Best Practitioners
            </h1>
          </div>
          <div className="h-9 w-4 flex flex-col items-start justify-start pt-7 px-0 pb-0 box-border">
            <div className="w-4 h-2 hidden flex-col items-start justify-start">
              <div className="w-px h-2 relative hidden" />
            </div>
          </div>
          <div className="w-[857px] hidden flex-row items-start justify-start max-w-full text-base text-text-secondary font-h5-medium">
            <div className="h-7 flex-1 relative tracking-[0.15px] leading-[175%] flex items-center max-w-full">
              Description
            </div>
          </div>
          <Component searchText={searchText} />
        </div>
      </div>
      <main className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
        <section className="w-[1400px] flex flex-col items-center pl-10 justify-center gap-[88px] max-w-full text-left text-lg text-neutral-8 font-web-primary-h3-primary-dm-sans lg:gap-[44px] mq750:gap-[22px]">
          <div className="self-stretch h-auto flex flex-row items-start justify-start gap-[40px] w-full mq750:gap-[20px]">
            <div className="h-[700px] w-[280px] shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] bg-neutral-1 shrink-0 flex flex-col items-start justify-start pt-0 px-0  box-border  lg:pb-[570px] lg:box-border mq750:hidden mq750:pb-60 mq750:box-border mq1050:pb-[370px] mq1050:box-border">
              <div className="w-[280px] h-[700px] bg-m3-white overflow-y-auto shrink-0 flex flex-col items-start justify-start p-4 box-border gap-[16px]">
                <div className="self-stretch shadow-[0px_-1px_0px_#f0f0f0_inset] bg-m3-white flex flex-row items-start justify-between pt-0 px-0 pb-2">
                  <b className="relative leading-[28px]">Filters</b>
                  <Button
                    disableElevation={true}
                    color="primary"
                    variant="text"
                    sx={{ borderRadius: "0px 0px 0px 0px", height: 30 }}
                    onClick={resetToInitialState}
                  >
                    Clear All
                  </Button>
                </div>
                <div className="self-stretch bg-m3-white overflow-hidden shrink-0 flex flex-col items-start justify-start text-sm text-m3-black">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <Accordion className="self-stretch">
                      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                        <Typography>Service Category</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {allCategories === "isLoading" ? (
                          <div>Loading...</div>
                        ) : (
                          <div className="self-stretch overflow-hidden flex flex-col items-center justify-start">
                            {allCategories.map((category) => (
                              <FormControlLabel
                                key={category.id}
                                className="self-stretch"
                                label={category.name}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={selectedCategories.includes(category.id)}
                                    onChange={() =>
                                      handleCategoryChange(category.id)
                                    }
                                  />
                                }
                              />
                            ))}
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion className="self-stretch">
                      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                        <Typography>Distance</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="self-stretch overflow-hidden flex flex-col items-center justify-start">
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="distance"
                              name="distance"
                              value={selectedDistance}
                              onChange={handleDistanceChange}

                            >
                              <FormControlLabel
                                value="auto"
                                control={<Radio color="primary" />}
                                label="Auto (25 Miles)"
                              />
                              <FormControlLabel
                                value="1"
                                control={<Radio color="primary" />}
                                label="1 Mile"
                              />
                              <FormControlLabel
                                value="5"
                                control={<Radio color="primary" />}
                                label="5 Miles"
                              />
                              <FormControlLabel
                                value="10"
                                control={<Radio color="primary" />}
                                label="10 Miles"
                              />
                              <FormControlLabel
                                value="25"
                                control={<Radio color="primary" />}
                                label="25 Miles"
                              />
                              <FormControlLabel
                                value="50+"
                                control={<Radio color="primary" />}
                                label="50+ Miles"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-center py-0 px-4 gap-[8px]">
                  <Button
                    className="flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.02)]"
                    color="primary"
                    variant="outlined"
                    sx={{ borderRadius: "0px 0px 0px 0px", height: 32 }}
                    onClick={resetToInitialState}
                  >
                    Reset
                  </Button>
                  <Button
                    className="flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
                    color="primary"
                    variant="contained"
                    disabled={!selectedCategories && !selectedDistance}
                    sx={{ borderRadius: "0px 0px 0px 0px", height: 30 }}
                    onClick={HandleSearchApply}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap   w-full text-5xl text-neutral-11 mq750:max-w-full">
              {displayPractitioners === "isLoading" || filtered === "isLoading" ? (
                <div className="flex items-center justify-center w-full">
                  <Loader />
                </div>
              ) : displayPractitioners.length > 0 ? (
                <div className="flex flex-wrap items-start justify-start gap-6">
                  {currentPractitioners.map((practitioner, index) => (
                    <div
                      className="cursor-pointer rounded-xl"
                      onClick={() => handleCardClick(practitioner.id ? practitioner.id : practitioner.userId)}
                    >
                      <CMPBusinessCard key={practitioner.id ? practitioner.id : practitioner.userId} data={practitioner} />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-sm">No search results found</div>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-[20px] mt-4">
            <Button className="bg-darkorange"
              variant="contained"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </Button>
            <Typography variant="body1">
              Page {currentPage} of {totalPages}
            </Typography>
            <Button className="bg-darkorange"
              variant="contained"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Button>
          </div>

          <footer className="self-stretch flex flex-col items-start justify-start gap-[32px] text-left text-mid text-gray-200 font-web-primary-h3-primary-dm-sans mq750:gap-[16px]">
            <div className="flex flex-row items-start justify-start gap-[54.9px] lg:flex-wrap mq750:gap-[27px]">
              <div className="w-[248px] flex flex-col items-start justify-start gap-[32px] text-mini text-gray-700">
                <div className="w-[236px] flex flex-row items-end justify-start gap-[12.4px]">
                  <img
                    className="h-[47px] w-[47.4px] relative overflow-hidden shrink-0"
                    alt=""
                    src="/the-healers-logo2.svg"
                  />
                  <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[12.2px]">
                    <img
                      className="self-stretch h-[22.5px] relative max-w-full overflow-hidden shrink-0"
                      alt=""
                      src="/the-healers3.svg"
                    />
                  </div>
                </div>
                <div className="self-stretch relative tracking-[-0.1px] leading-[26px] mix-blend-normal">
                  Your all-in-one platform for effortless wellness, connecting
                  you with curated fitness, wellness services and personalized
                  recommendations.
                </div>
              </div>
              <div className="w-[186.8px] flex flex-col items-start justify-start gap-[16px]">
                <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                  Company
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  About us
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  Contact us
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  Privacy Policy
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  Terms and Conditions
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[16px]">
                <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                  Services
                </div>
                <div className="w-[186.8px] relative tracking-[-0.2px] inline-block">
                  Mind
                </div>
                <div className="w-[186.8px] relative tracking-[-0.2px] inline-block">
                  Body
                </div>
                <div className="w-[186.8px] relative tracking-[-0.2px] inline-block">
                  Medicine
                </div>
                <div className="relative tracking-[-0.2px] leading-[22px]">
                  Psychologists and Counselors
                </div>
              </div>
              <div className="w-[186.8px] flex flex-col items-start justify-start gap-[16px]">
                <div className="w-[88.2px] relative text-mini tracking-[-0.1px] leading-[26px] text-gray-700 inline-block mix-blend-normal">
                  Links
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  Sitemap
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  Security
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  Support Center
                </div>
                <div className="self-stretch relative tracking-[-0.2px]">
                  Cookie Preferences
                </div>
              </div>
              <div className="w-[135.9px] flex flex-col items-start justify-start gap-[16px] text-4xs-4 text-neutral-1 font-open-sans">
                <img
                  className="self-stretch h-10 relative max-w-full overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/app-store--filled1.svg"
                />
                <div className="self-stretch flex flex-row items-start justify-start">
                  <div className="flex-1 flex flex-row items-end justify-start pt-[4.6px] pb-1.5 pr-[9.2px] pl-2.5 relative gap-[19.8px]">
                    <img
                      className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full"
                      alt=""
                      src="/vector.svg"
                    />
                    <img
                      className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full z-[1]"
                      alt=""
                      src="/vector-1.svg"
                    />
                    <div className="flex flex-col items-start justify-end pt-0 px-0 pb-[3.2px]">
                      <img
                        className="w-[11.1px] h-[21.5px] relative z-[4]"
                        alt=""
                        src="/vector-5.svg"
                      />
                    </div>
                    <img
                      className="h-[13.4px] w-[16.2px] absolute !m-[0] bottom-[7.2px] left-[10.1px] z-[2]"
                      alt=""
                      src="/vector-2.svg"
                    />
                    <img
                      className="h-[11px] w-[12.5px] absolute !m-[0] top-[calc(50%_-_5.5px)] left-[20.5px] z-[3]"
                      alt=""
                      src="/vector-4.svg"
                    />
                    <div className="flex-1 flex flex-col items-start justify-start gap-[1.4px]">
                      <div className="relative inline-block [-webkit-text-stroke:0.2px_#fff] min-w-[40px] z-[2]">
                        GET IT ON
                      </div>
                      <img
                        className="self-stretch h-[17px] relative max-w-full overflow-hidden shrink-0 z-[2]"
                        alt=""
                        src="/vector-3.svg"
                      />
                    </div>
                    <img
                      className="h-[12.9px] w-[16.3px] absolute !m-[0] top-[7.1px] left-[10px] z-[5]"
                      alt=""
                      src="/vector-6.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch h-[58px] overflow-hidden shrink-0 flex flex-col items-start justify-start pt-[0.5px] px-0 pb-[55.5px] box-border gap-[29.5px] text-mini mq750:h-auto">
              <img
                className="self-stretch relative max-w-full overflow-hidden max-h-full shrink-0 [debug_commit:1de1738]"
                loading="lazy"
                alt=""
                src="/line-copy.svg"
              />
              <img
                className="w-[15.1px] h-[13px] relative hidden"
                alt=""
                src="/heart2.svg"
              />
              <div className="self-stretch flex flex-row items-start justify-between shrink-0 [debug_commit:1de1738] gap-[20px] mq750:flex-wrap">
                <div className="w-[235px] relative tracking-[-0.1px] leading-[36px] inline-block shrink-0 [debug_commit:1de1738]">
                  © 2024 Copyright, All Right Reserved.
                </div>
                <div className="w-48 flex flex-col items-start justify-start pt-[2.1px] px-0 pb-0 box-border">
                  <div className="self-stretch flex flex-row items-start justify-between shrink-0 [debug_commit:1de1738] gap-[20px]">
                    <img
                      className="h-6 w-6 relative min-h-[24px]"
                      alt=""
                      src="/buttontwitter.svg"
                    />
                    <img
                      className="h-6 w-6 relative min-h-[24px]"
                      alt=""
                      src="/buttonfacebook.svg"
                    />
                    <img
                      className="h-6 w-6 relative object-cover min-h-[24px]"
                      alt=""
                      src="/logoinstagram@2x.png"
                    />
                    <img
                      className="h-6 w-6 relative object-cover min-h-[24px]"
                      alt=""
                      src="/logolinkedin@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </main>
    </div>
  );
};

export default SearchResultsPractitioners;
