import { initializeApp } from "firebase/app";
import { getMessaging } from 'firebase/messaging';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyACAFM4lCUFWKSjufYqFvHeOvaiowdVjZU",
  authDomain: "healers-dev-qa-uat.firebaseapp.com",
  projectId: "healers-dev-qa-uat",
  storageBucket: "healers-dev-qa-uat.appspot.com",
  messagingSenderId: "324149421998",
  appId: "1:324149421998:web:d662bb1e65775daaf48a3f",
  measurementId: "G-871R9LVRQP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const firestore = getFirestore(app);
export { messaging };
export { firestore };
