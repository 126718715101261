import React, { useState, useEffect } from "react";
import {
  LinearProgress,
  InputAdornment,
  Icon,
  IconButton,
  Button,
  Box,
  TextField,

} from "@mui/material";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addPractitionerTag,
  addPractitionerBussiness,
  uploadUserImages,
  resetProfileDetailing,
  addBio,
  addCategory,
  resetCategory,
  resetBio,
} from "../../Actions/Practitioner/ProfileDetailingAction";
import Loader from "../../components/SubComponent/Loader";
import { getAll } from "../../Actions/Catagories/GetCategoriesAction";
import { getPractitioner } from "../../Actions/Consumer/GetPractitionerAction";

const IntroDetails = () => {
  useEffect(() => {
    dispatch(resetProfileDetailing());
    dispatch(getAll());
  }, []);
  const profileDetailingState = useSelector((state) => state.profileDetailing);
  const CategoryState = useSelector((state) => state.addCategory.addCategory);
  const BioState = useSelector((state) => state.addBio.addBio);
  const practitionerData = useSelector((state)=>state.practitioner.practitioner)
  console.log(practitionerData, "practitioner state");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [details, setdetails] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));
  const userCategories = practitionerData && practitionerData.length !== 0&& practitionerData.result && practitionerData.result[0].categories;
  // const userCategories = [];
  const allCategories = useSelector((state) => Array.isArray(state.getCategories.getAll) ? state.getCategories.getAll : []);
  const [categoryTags, setCategoryTags] = useState([]);
  console.log(
    userCategories, 'categories'
  )
  const handleKeyDown = (event, categoryId) => {
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      const currentTags = categoryTags[categoryId] || [];  // Ensure it's defined, fallback to an empty array
      const currentTagsCount = currentTags.length;
      const newTag = event.target.value.trim();
  
      if (currentTagsCount !== 8) {
        setCategoryTags((prevTags) => ({
          ...prevTags,
          [categoryId]: [...currentTags, newTag],  // Use the currentTags array
        }));
      } else {
        toast.error("You cannot add more than 8 tags", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
  
      event.target.value = "";
    }
  };
  

  const handleDelete = (categoryId, tagToDelete) => () => {
    setCategoryTags((prevTags) => ({
      ...prevTags,
      [categoryId]: prevTags[categoryId].filter((tag) => tag !== tagToDelete),
    }));
  };
  const changeScreen1 = () => {
    setdetails(3);
  };
  const changeScreen2 = () => {
    setdetails(5);
  };
  const changeScreen4 = () => {
    setdetails(4);
  };

  const changeScreen3 = () => {
    navigate("/practitioner");
  };
  const backtoPrev1 = () => {
    setdetails(1);
  };
  const backtoPrev4 = () => {
    setdetails(2);
  };
  console.log(details, "details screen");

  const backtoPrev2 = () => {
    setdetails(4);
  };
  const backtoPrev3 = () => {
    setdetails(3);
  };
  const navigateToNext1 = () => {
    setdetails(2);
    const id =user.user.id;
   dispatch(resetCategory());
   dispatch(getPractitioner(id));
  };
  const navigateToNext5 = () => {
    setdetails(4);
   dispatch(resetBio());
  };
  const navigateToNext2 = () => {
    dispatch(resetProfileDetailing());
    setdetails(3);
   
  };
  const navigateToNext3 = () => {
    dispatch(resetProfileDetailing());
    setdetails(5);
  };
  const navigateToNext4 = () => {
    dispatch(resetProfileDetailing());
    navigate("/practitioner");
  };

  const handleNextClick = async () => {
    const categoriesWithInsufficientTags = Object.entries(categoryTags)
      .filter(([categoryId, tags]) => tags.length < 3)
      .map(([categoryId]) => categoryId);

    if (categoriesWithInsufficientTags.length > 0) {
      toast.error("You must add atleast 3 tags", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const body = {
        userId: user.user.id,
        categoriesWithTags: Object.entries(categoryTags).map(
          ([categoryId, tags]) => ({
            categoryId,
            tags: tags.join(", "),
          })
        ),
      };
      dispatch(addPractitionerTag(body))
      .then(() => {

        console.log(categoryTags,'tags added successfully');
      })
      .catch((error) => {

        console.error('Error added Tags', error);
      });
    }
  };
  const handleNextClick1 = async () => {
    if (
      (offline && (address == "")) ||
      description == "" ||
      (online === false && offline === false)
    ) {
      toast.error("Please fill in the details", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let session;
      if (online && offline) {
        session = 2;
      } else if (online) {
        session = 0;
      } else if (offline) {
        session = 1;
      }
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const body = {
        userId: user.user.id,
        sessionType: session,
        address: address,
        description: description,
        timeZone: timeZone,
      };
      dispatch(addPractitionerBussiness(body));
    }
  };
  useEffect(() => {
    CategoryState && CategoryState.length !== 0 && CategoryState.status && CategoryState.status === true && navigateToNext1();
    BioState && BioState.length !== 0 && BioState.status && BioState.status === true && navigateToNext5();
   
  }, [CategoryState,BioState,navigate]);
  const handleNextClick3 = () => {
    if (!selectedCategory) {
      toast.error("Please select a category", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    
    const categoryId = selectedCategory;
    const userId = user.user.id;

    dispatch(addCategory(categoryId, userId))
      .then(() => {

        console.log('Category added successfully');
      })
      .catch((error) => {

        console.error('Error added category', error);
      });
  }
 
  const handleNextClick4 = () => {
    if (!professionalTitle) {
      toast.error("Please add a title", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const body = {
      userId: user.user.id,
      bio:professionalTitle
    };
    dispatch(addBio(body))
      .then(() => {

        console.log('Bio added successfully');
      })
      .catch((error) => {

        console.error('Error added Bio', error);
      });
  }
  const handleNextClick2 = async () => {
    if (!logoImage) {
      toast.error("Please upload a profile image.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    if (galleryImages.length === 0) {
      toast.error("Please upload at least one gallery image.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    const body = new FormData();
    body.append("userId", user.user.id);
    body.append("profileImage", logoImage);
    galleryImages.forEach((image) => {
      body.append("Images", image);
    });
  
    dispatch(uploadUserImages(body));
    setProfileData();
  };
  
  const setProfileData = async () => {
    let session;
    if (online && offline) {
      session = 2;
    } else if (online) {
      session = 0;
    } else if (offline) {
      session = 1;
    }
    const body = {
      userId: user.user.id,
      profileImage: logoImage,
      Images: galleryImages,
      address: address,
      description: description,
      sessionType: session,
      categoriesWithTags: Object.entries(categoryTags).map(
        ([categoryId, tags]) => ({
          categoryId,
          tags: tags.join(", "),
        })
      ),
    };
    localStorage.setItem("profileData", JSON.stringify(body));
  };
  const [online, setOnline] = useState(false);
  const [offline, setOffline] = useState(false);

  const handleOnlineChange = () => {
    setOnline(!online);
  };

  const handleOfflineChange = () => {
    setOffline(!offline);
  };
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const [logoImage, setLogoImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);

  const handleLogoImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 1048576) {
      const fileType = file.type.split("/")[1];
      if (["png", "jpg", "jpeg"].includes(fileType)) {
        setLogoImage(file);
      } else {
        toast.error("Only PNG, JPG, and JPEG file types are allowed.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Maximum file size allowed is 1MB.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleGalleryImageChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedFiles = [];
    files.forEach((file) => {
      if (file.size <= 1048576) {
        const fileType = file.type.split("/")[1];
        if (["png", "jpg", "jpeg"].includes(fileType)) {
          allowedFiles.push(file);
        } else {
          toast.error("Only PNG, JPG, and JPEG file types are allowed.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Maximum file size allowed is 1MB.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
    setGalleryImages((prevImages) => [...prevImages, ...allowedFiles]);
  };

  const removeGalleryImage = (index) => {
    setGalleryImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleSelect = (category) => {
    setSelectedCategory(category);
  };
  const getRows = (categories) => {
    const rows = [];
    for (let i = 0; i < categories.length; i += 2) {
      rows.push(categories.slice(i, i + 2));
    }
    return rows;
  };

  const rows = getRows(allCategories);
  const [professionalTitle, setProfessionalTitle] = useState("");

  const handleTitleChange = (event) => {
    setProfessionalTitle(event.target.value);
  };
 
 
 
  console.log(CategoryState, "add category status");
console.log(profileDetailingState, "profile detail state");

  return (
    <div className="w-[850px] rounded-2xl shadow-[0px_3px_6px_-4px_rgba(0,_0,_0,_0.12),_0px_6px_16px_rgba(0,_0,_0,_0.08),_0px_9px_28px_8px_rgba(0,_0,_0,_0.05)] max-w-full flex bg-white flex-row items-start justify-start ml-6 box-border leading-[normal] tracking-[normal] mq675:gap-[19px]">
      {details == 1 && (
        <section className="flex-1 rounded-2xl bg-white box-border flex flex-col items-start justify-start pl-6 pt-6 pr-6 pb-6 gap-[24px] max-h-[700px] max-w-full text-left text-13xl text-m3-black font-web-secondary-caption">
          <Box className="self-stretch h-[11px] relative mix-blend-normal" sx={{ width: "100%", marginTop: "5px" }}>
            <LinearProgress variant="determinate" value={12} sx={{ height: 10, borderRadius: 5 }} />
          </Box>

          <div className="self-stretch flex flex-col items-start justify-start max-w-full text-13xl">
            <h1 className="m-0 self-stretch text-inherit text-2xl font-bold leading-[36px]">
              What is your primary healing modality?
            </h1>
            <p className="mt-1 text-sm text-gray-600">
              Identify the main approach you use for healing: What's your go-to method?
            </p>
          </div>

          <div className="self-stretch flex flex-col gap-[16px]">
            {rows.length === 0 ? (
              <p>No categories available.</p>
            ) : (
              rows.map((row, rowIndex) => (
                <div className="flex flex-row gap-[16px]" key={rowIndex}>
                  {row.map((category) => (
                    <div
                      key={category.id}
                      className="relative flex-1 flex flex-col items-start justify-start bg-[#F4F1FC] border-[3px] rounded-[8px] h-[70px] p-3 box-border cursor-pointer"
                      style={{
                        border: `1px solid ${selectedCategory === category.id ? "#6366F1" : "transparent"}`,
                      }}
                      onClick={() => handleSelect(category.id)}
                    >
                      <h1 className="text-[16px] font-bold" style={{ margin: '0' }}>{category.name}</h1>
                      <p className="text-[14px] text-gray-800">{category.displayName}</p>
                      {selectedCategory === category.id && (
                        <img
                          src="/vector.png"
                          alt="Selected"
                          className="absolute right-4 top-1/2 transform -translate-y-1/2 w-[18px] h-[12px]"
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))
            )}
          </div>

          <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[32px] max-w-full mt-6">
            <Button
      className="h-[60px] flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] min-w-[370px] max-w-full"
      variant="contained"
      onClick={handleNextClick3}
      // onClick={navigateToNext1()}
      sx={{
        textTransform: "none",
        color: "#fff",
        fontSize: "18px",
        background: "#006a6a",
        border: "#006a6a solid 1px",
        borderRadius: "8px",
        "&:hover": { background: "#006a6a" },
        height: 60,
      }}
    >
     {CategoryState === "isLoading" ? (
              <div className="mt-2">
                <Loader />
              </div>
             ) 
          
             : (
              "Next"
            )}
    </Button>
         


          </div>
        </section>
      )}

      {details == 2 && (
     <section className="flex-1 rounded-2xl bg-white box-border flex flex-col items-start justify-start pl-6 pt-6 pr-6 pb-6 gap-[24px] max-h-[700px] max-w-full text-left text-13xl text-m3-black font-web-secondary-caption">
     <Box className="self-stretch h-[11px] relative mix-blend-normal" sx={{ width: "100%", marginTop: "5px" }}>
       <LinearProgress variant="determinate" value={12} sx={{ height: 10, borderRadius: 5 }} />
     </Box>
   
    
       <div className="self-stretch flex flex-row flex-wrap items-start justify-start max-w-full [row-gap:20px]">
            <div className="flex-1 flex flex-col items-start justify-start min-w-[491px] max-w-full mq675:min-w-full">
              <h1 className="m-0 self-stretch relative text-inherit leading-[48px] font-bold font-inherit whitespace-nowrap mq450:text-lgi mq450:leading-[29px] mq900:text-7xl mq900:leading-[38px] mq750:text-7xl mq750:leading-[38px]">
              How do you refer to yourself professionally?
              </h1>
              <div className="self-stretch relative text-sm leading-[22px] whitespace-nowrap">
              Define your professional identity: What title or role best represents you?
              </div>
            </div>
            <Button
              disableElevation={true}
              variant="text"
              sx={{ borderRadius: "0px 0px 0px 0px" }}
              onClick={changeScreen4}
            >
              Skip for now
            </Button>
          </div>
       <div className="self-stretch flex flex-col gap-[16px]">
      
         <div className="flex items-center">
           <label
             className="text-lg font-semibold flex items-center justify-center border border-black"
             style={{
               height: "57px", // Same as TextField height
               backgroundColor: "#FAFAFA", // Same as background color
               padding: "0 16px",
               border:'1px solid #D9D9D9', // Adjust padding for spacing
               borderRadius: "4px 0 0 4px", // Rounded corners on the left
             }}
           >
             I am a
           </label>
           <TextField
             className="flex-1 bg-[#FAFAFA]"
             variant="outlined"
             value={professionalTitle}
             onChange={handleTitleChange}
             sx={{
               "& .MuiOutlinedInput-root": {
                 height: 57,
                 borderRadius: "0 4px 4px 0", 
                 border: "1px solid #D9D9D9", 
                 display: "flex",
                 alignItems: "center",
                 borderLeft:'none'
                
               },
               "& .MuiOutlinedInput-input": {
                 padding: "0 16px", // Padding inside the text field
               },
             }}
           />
         </div>
         <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[32px] max-w-full mq675:gap-[16px]">
         <IconButton
            className="h-[50px] w-[50px] shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)]"
            sx={{ borderRadius: "0px 0px 0px 0px" }}
            color="primary"
            onClick={backtoPrev1}
          >
            <Icon>arrow_back_ios_sharp</Icon>
          </IconButton>
            <Button
              className="h-[60px] flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] min-w-[370px] max-w-full mq675:min-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
              variant="contained"
             onClick={handleNextClick4}
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "18",
                background: "#006a6a",
                border: "#006a6a solid 1px",
                borderRadius: "8px",
                "&:hover": { background: "#006a6a" },
                height: 60,
              }}
            >
              {BioState === "isLoading" ? (
              <div className="mt-2">
                <Loader />
              </div>
             ) 
          
             : (
              "Next"
            )}
            </Button>
          </div>
       </div>
     </section>
)}




      {details == 4 && (
        <section className="flex-1 rounded-2xl bg-white box-border flex flex-col items-start justify-start p-6 box-border gap-[24px] max-h-[700px] max-w-full text-left text-13xl text-m3-black font-web-secondary-caption mq675:pt-5 mq675:pb-5 mq675:box-border mq900:pt-5 mq900:pb-5 mq900:box-border">
          <Box
            className="self-stretch h-[11px] relative mix-blend-normal"
            sx={{ width: "100%", marginTop: "5px" }}
          >
            <LinearProgress
              variant="determinate"
              value={33}
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Box>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start max-w-full [row-gap:20px]">
            <div className="flex-1 flex flex-col items-start justify-start min-w-[491px] max-w-full mq675:min-w-full">
              <h1 className="m-0 self-stretch relative text-inherit leading-[48px] font-bold font-inherit whitespace-nowrap mq450:text-lgi mq450:leading-[29px] mq900:text-7xl mq900:leading-[38px] mq750:text-7xl mq750:leading-[38px]">
                Add Tags
              </h1>
              <div className="self-stretch relative text-sm leading-[22px] whitespace-nowrap">
                Add 3 to 8 related tags for respective categories, this will
                help users find you easily. Please press enter to add a tag.
              </div>
            </div>
            <Button
              disableElevation={true}
              variant="text"
              sx={{ borderRadius: "0px 0px 0px 0px" }}
              onClick={changeScreen1}
            >
              Skip for now
            </Button>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full text-base">
            {userCategories.map((category) => (
              <div
                key={category.id}
                className="self-stretch relative leading-[24px] font-semibold"
              >
                {category.displayName}:
                <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 box-border gap-[16px] max-w-full text-xs text-text-secondary">
                  <div className="self-stretch flex flex-row items-start justify-start max-w-full">
                    <TextField
                      className="[border:none] bg-white h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
                      color="primary"
                      label="Add Tags"
                      helperText="add 3 to 8 tags"
                      fullWidth={true}
                      variant="standard"
                      onKeyDown={(event) => handleKeyDown(event, category.id)}
                      sx={{ "& .MuiInputBase-root": { height: "57px" } }}
                    />
                    <div className="flex-1 hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border max-w-full">
                      <div className="self-stretch relative leading-[18px] flex items-center whitespace-nowrap max-w-full">
                        You can add 3 to 8 tags.
                      </div>
                    </div>
                  </div>
                  <div className=" items-start justify-start py-0 pl-0 box-border gap-[16px] min-h-[30px] max-w-[200px]">
                    <Stack direction="row" spacing={1}>
                    {categoryTags[category.id]?.map((tag, index) => (
  <Chip
    key={index}
    label={tag}
    size="small"
    onDelete={handleDelete(category.id, tag)}
  />
))}

                    </Stack>
                  </div>
                  <div className="self-stretch h-px flex flex-row items-start justify-start pt-px px-0 pb-0 box-border max-w-full">
                    <div className="h-px w-0 relative overflow-hidden shrink-0 hidden" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[32px] max-w-full mq675:gap-[16px]">
          <IconButton
            className="h-[50px] w-[50px] shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)]"
            sx={{ borderRadius: "0px 0px 0px 0px" }}
            color="primary"
            onClick={backtoPrev4}
          >
            <Icon>arrow_back_ios_sharp</Icon>
          </IconButton>
       
          <Button
                       className="h-[60px] flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] min-w-[370px] max-w-full mq675:min-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
                       variant="contained"
            onClick={handleNextClick}
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "18",
              background: "#006a6a",
              border: "#006a6a solid 1px",
              borderRadius: "8px",
              "&:hover": { background: "#006a6a" },
              height: 60,
            }}
          >
         {profileDetailingState.profileDetailing === "isLoading" ? (
  <div className="mt-2">
    <Loader />
  </div>
) : profileDetailingState?.profileDetailing?.status === true ? (
  navigateToNext2()
) : (
  "Next"
)}
          </Button>
          </div>
        </section>
      )}
      {details == 3 && (
        <section className="flex-1 rounded-2xl bg-white box-border flex flex-col items-start justify-start pl-6 pt-6 pr-6 pb-6 box-border gap-[24px] max-h-[700px] max-w-full text-left text-13xl text-m3-black font-web-secondary-caption mq675:pt-5 mq675:pb-5 mq675:box-border mq900:pt-5 mq900:pb-5 mq900:box-border">
          <Box
            className="self-stretch h-[11px] relative mix-blend-normal"
            sx={{ width: "100%", marginTop: "5px" }}
          >
            <LinearProgress
              variant="determinate"
              value={66}
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Box>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start max-w-full [row-gap:20px] text-13xl">
            <div className="flex-1 flex flex-col items-start justify-start min-w-[349px] max-w-full mq675:min-w-full">
              <h1 className="m-0 self-stretch relative text-inherit leading-[48px] font-bold font-inherit mq450:text-lgi mq450:leading-[29px] mq750:text-7xl mq750:leading-[38px]">
                Add Description
              </h1>
              <div className="self-stretch relative text-sm leading-[22px]"></div>
            </div>
            <div className="rounded overflow-hidden flex flex-row items-start justify-start py-1 px-4 gap-[10px]">
              <div className="bg-hitbox-1000 overflow-hidden hidden flex-col items-center justify-center">
                <img
                  className="w-3.5 h-3.5 relative overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/wrapper1@2x.png"
                />
              </div>
              <Button
                className="relative min-w-[83px]"
                disableElevation={true}
                variant="text"
                sx={{ borderRadius: "0px 0px 0px 0px" }}
                onClick={changeScreen2}
              >
                Skip for now
              </Button>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base">
            <div className="relative leading-[24px] font-semibold inline-block min-w-[105px]">
              Session Type:
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[24px]">
              <div className="flex-1 flex flex-row items-start justify-start gap-[16px] min-w-[204px]">
                <input
                  className="m-0 h-6 w-6 relative rounded overflow-hidden shrink-0 min-h-[24px]"
                  type="checkbox"
                  checked={online}
                  onChange={handleOnlineChange}
                />
                <div className="flex-1 relative leading-[24px]">Online</div>
              </div>
              <div className="flex-1 flex flex-row items-start justify-start gap-[16px] min-w-[204px] mq450:flex-wrap">
                <input
                  className="m-0 h-6 w-6 relative rounded overflow-hidden shrink-0"
                  type="checkbox"
                  checked={offline}
                  onChange={handleOfflineChange}
                />
                <div className="flex-1 relative leading-[24px] inline-block min-w-[45px]">
                  On-site
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch h-[47px] flex flex-row items-start justify-start pt-0 px-0 pb-0 box-border max-w-full text-text-secondary font-components-helper-text">
            <TextField
              className="[border:none] bg-[transparent] h-[57px] flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 min-w-[250px] max-w-full"
              color="primary"
              label="Address"
              fullWidth={true}
              variant="standard"
              value={address}
              disabled={!offline}
              onChange={handleAddressChange}
              sx={{ "& .MuiInputBase-root": { height: "57px" } }}
            />
            <div className="h-5 w-[220px] hidden flex-col items-start justify-start pt-[3px] px-0 pb-0 box-border">
              <div className="self-stretch relative tracking-[0.4px] leading-[166%]">
                Helper text
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start max-w-full text-text-secondary">
            <TextField
              className="flex-1 font-web-secondary-caption text-base text-m3-ref-neutral-neutral20-200 max-w-full"
              color="primary"
              rows={5}
              label="Description"
              variant="standard"
              multiline
              value={description}
              onChange={handleDescriptionChange}
            />
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[32px] max-w-full mq675:gap-[16px]">
            <IconButton
              className="h-[50px] w-[50px] shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)]"
              sx={{ borderRadius: "0px 0px 0px 0px" }}
              color="primary"
              onClick={backtoPrev2}
            >
              <Icon>arrow_back_ios_sharp</Icon>
            </IconButton>
            <Button
              className="h-[60px] flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] min-w-[370px] max-w-full mq675:min-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
              variant="contained"
              onClick={handleNextClick1}
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "18",
                background: "#006a6a",
                border: "#006a6a solid 1px",
                borderRadius: "8px",
                "&:hover": { background: "#006a6a" },
                height: 60,
              }}
            >
 {profileDetailingState.profileDetailing === "isLoading" ? (
  <div className="mt-2">
    <Loader />
  </div>
) : profileDetailingState?.profileDetailing?.status === true ? (
                navigateToNext3()
              ) : (
                "Next"
              )}
            </Button>
          </div>
        </section>
      )}



      {details == 5 && (
        <section className="flex-1 rounded-2xl bg-white box-border flex flex-col items-start justify-start pl-6 pt-6 pr-6 pb-6 box-border gap-[24px] max-h-[700px] max-w-full text-left text-13xl text-m3-black font-web-secondary-caption mq675:pt-5 mq675:pb-5 mq675:box-border mq900:pt-5 mq900:pb-5 mq900:box-border">
          <Box
            className="self-stretch h-[11px] relative mix-blend-normal"
            sx={{ width: "100%", marginTop: "5px" }}
          >
            <LinearProgress
              variant="determinate"
              value={100}
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Box>
          <div className="self-stretch flex flex-col items-start justify-start gap-[32px] max-w-full text-left text-base text-m3-black font-web-secondary-subtitle1 mq675:gap-[16px]">
            <div className="self-stretch flex flex-row items-start justify-between max-w-full [row-gap:20px] gap-[0px] text-13xl mq750:flex-wrap">
              <div className="w-[537px] flex flex-col items-start justify-start max-w-full">
                <h1 className="m-0 self-stretch relative text-inherit leading-[48px] font-bold font-inherit mq450:text-lgi mq450:leading-[29px] mq750:text-7xl mq750:leading-[38px]">
                  Your Identity
                </h1>
                <div className="self-stretch relative text-sm leading-[22px]">
                  Add your logo and images.
                </div>
              </div>
              <div className="rounded overflow-hidden flex flex-row items-center justify-center py-1 px-4 gap-[10px]">
                <div className="bg-hitbox-1000 overflow-hidden hidden flex-col items-center justify-center">
                  <img
                    className="w-3.5 h-3.5 relative overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/wrapper1@2x.png"
                  />
                </div>
                <Button
                  className="flex-1 relative min-w-[83px]"
                  disableElevation={true}
                  variant="text"
                  sx={{ borderRadius: "0px 0px 0px 0px" }}
                  onClick={changeScreen3}
                >
                  Skip for now
                </Button>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px] max-w-full">
              <div className="self-stretch relative leading-[24px] font-semibold">
                Logo
              </div>
              <div className="self-stretch flex flex-row flex-wrap items-center justify-start py-0 pr-[191px] pl-0 box-border gap-[20px] max-w-full text-sm font-poppins mq675:pr-[95px] mq675:box-border mq450:pr-5 mq450:box-border">
                <img
                  className="h-[100px] w-[100px] relative"
                  loading="lazy"
                  alt=""
                  src={
                    logoImage
                      ? URL.createObjectURL(logoImage)
                      : "/component-placeholder-image.svg"
                  }
                />
                <div className="flex-1 flex flex-col items-start justify-start gap-[10px] min-w-[222px] max-w-full">
                  <i className="relative leading-[22px] font-light">
                    Please upload square image, size less than 1MB
                  </i>
                  <input
                    className="self-stretch rounded-8xs bg-gray1-200 flex flex-row items-center justify-start py-2.5 pr-[50px] pl-2.5 mq450:flex-wrap mq450:gap-[15px] mq450:pr-5 mq450:box-border"
                    type="file"
                    accept="image/*"
                    onChange={handleLogoImageChange}
                  />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
              <div className="self-stretch relative leading-[24px] font-semibold">
                Gallery Images
              </div>
              <div>
                {galleryImages.map((image, index) => (
                  <div key={index} className="relative inline-block">
                    <img
                      className="h-[100px] w-[100px] relative"
                      loading="lazy"
                      alt=""
                      src={URL.createObjectURL(image)}
                    />
                    <IconButton
                      className="absolute top-0 right-0 p-1 bg-white bg-opacity-75"
                      onClick={() => removeGalleryImage(index)}
                    >
                      <Icon>close</Icon>
                    </IconButton>
                  </div>
                ))}
              </div>
              <input
                className="self-stretch rounded-4xs overflow-hidden flex flex-col items-center justify-center py-[38px] px-[39px] border-[1px] border-dashed border-lightsteelblue"
                type="file"
                accept="image/*"
                onChange={handleGalleryImageChange}
                multiple
                disabled={galleryImages.length == 3}
              />
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[32px] max-w-full mq675:gap-[16px]">
              <IconButton
                className="h-[50px] w-[50px] shadow-[0px_2px_8px_rgba(0,_0,_0,_0.15)]"
                sx={{ borderRadius: "0px 0px 0px 0px" }}
                color="primary"
                onClick={backtoPrev3}
              >
                <Icon>arrow_back_ios_sharp</Icon>
              </IconButton>
              <Button
                className="h-[60px] flex-1 shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)] min-w-[370px] max-w-full mq675:min-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
                variant="contained"
                onClick={handleNextClick2}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "18",
                  background: "#006a6a",
                  border: "#006a6a solid 1px",
                  borderRadius: "8px",
                  "&:hover": { background: "#006a6a" },
                  height: 60,
                }}
              >

                {profileDetailingState.profileDetailing === "isLoading" ? (
                  <div className="mt-2">
                    <Loader />
                  </div>
                ) : profileDetailingState.profileDetailing.status &&
                  profileDetailingState.profileDetailing.status == true ? (
                  navigateToNext4()
                ) : (
                  "Next"
                )}
              </Button>
            </div>
          </div>
        </section>
      )}{" "}
    </div>
  );
};

export default IntroDetails;