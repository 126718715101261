import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { getConsumerBooking, getConsumerHistoryBooking, getConsumerPendingBooking, getConsumerUpcomingBooking } from "../../Actions/Consumer/ConsumerGetBookingAction";
import Loader from "../SubComponent/Loader";
import AppointmentCardHistoryConsumer from "../SubComponent/AppointmentCardHistoryConsumer";
import AppointmentCardUpcomingConsumer from "../SubComponent/AppointmentCardUpcomingConsumer";
import AppointmentCardPendingConsumer from "../SubComponent/AppointmentCardPendingConsumer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ConsumerSchedule = () => {
  const UserData = JSON.parse(localStorage.getItem("user"));
  const consumerId = UserData.user.id;
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const [currentPageUpcoming, setCurrentPageUpcoming] = useState(1);
  const [currentPagePending, setCurrentPagePending] = useState(1);
  const [currentPageHistory, setCurrentPageHistory] = useState(1);
  const [totalPagesUpcoming, setTotalPagesUpcoming] = useState(1);
  const [totalPagesPending, setTotalPagesPending] = useState(1);
  const [totalPagesHistory, setTotalPagesHistory] = useState(1);

  const handlePageChange = (newPage) => {
    switch (tabIndex) {
      case 0:
        if (newPage > 0 && newPage <= totalPagesUpcoming) {
          setCurrentPageUpcoming(newPage);
        }
        break;
      case 1:
        if (newPage > 0 && newPage <= totalPagesPending) {
          setCurrentPagePending(newPage);
        }
        break;
      case 2:
        if (newPage > 0 && newPage <= totalPagesHistory) {
          setCurrentPageHistory(newPage);
        }
        break;
      default:
        break;
    }
    fetchBookings(newPage);
  };

  useEffect(() => {
    fetchBookings();
  }, [dispatch, consumerId, tabIndex, currentPageUpcoming, currentPagePending, currentPageHistory]);
  const fetchBookings = () => {
    const pageNumber = 
    tabIndex === 0 ? currentPageUpcoming :
    tabIndex === 1 ? currentPagePending :
    tabIndex === 2 ? currentPageHistory : 1;
  
  
    const action = 
      tabIndex === 0 ? getConsumerUpcomingBooking :
      tabIndex === 1 ? getConsumerPendingBooking :
      tabIndex === 2 ? getConsumerHistoryBooking : null;
  
    if (action) {
      dispatch(action(consumerId, pageNumber));
    }
  };
  

  const bookings = useSelector((state) => state.getConsumerBooking.getConsumerBooking);
  const historyBookings = useSelector((state) => state.getConsumerBooking.getConsumerHistoryBooking);
  const upcomingBookings = useSelector((state) => state.getConsumerBooking.getConsumerUpcomingBooking);
  const pendingBookings = useSelector((state) => state.getConsumerBooking.getConsumerPendingBooking);

  useEffect(() => {
    if (upcomingBookings && upcomingBookings.totalCount) {
      setTotalPagesUpcoming(Math.ceil(upcomingBookings.totalCount / 10));
    }
    if (pendingBookings && pendingBookings.totalCount) {
      setTotalPagesPending(Math.ceil(pendingBookings.totalCount / 10));
    }
    if (historyBookings && historyBookings.totalCount) {
      setTotalPagesHistory(Math.ceil(historyBookings.totalCount / 10));
    }
  }, [upcomingBookings, pendingBookings, historyBookings]);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    
   
  };

  const handleBookingUpdate = () => {
    setUpdateCount((prev) => prev + 1);
  };

  return (
    <div className="self-stretch flex flex-col pt- items-start justify-start h-[82vh] gap-[16px] max-w-full text-left text-base text-m3-black font-web-secondary-body2 mq750:gap-[28px] ">
      <div className="w-[444px] flex flex-col items-start justify-start gap-[8px] max-w-full text-13xl">
        <h1 className="m-0 relative text-inherit leading-[48px] font-bold font-inherit mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
          Schedule
        </h1>
        <div className="self-stretch relative text-sm leading-[22px]">
          View your upcoming and completed sessions
        </div>
      </div>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="appointment tabs">
        <Tab label="Upcoming" />
        <Tab label="Pending" />
        <Tab label="History" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-2 px-2 box-border gap-[24px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border h-[calc(100vh-400px)] overflow-y-auto">
          {upcomingBookings === "isLoading" && <div><Loader /></div>}
          {upcomingBookings && upcomingBookings !== "isLoading" && upcomingBookings.length !== 0 && upcomingBookings.result.length !== 0 &&
            upcomingBookings.result.map((booking) => (
              <AppointmentCardUpcomingConsumer
                key={booking.id}
                booking={booking}
                onBookingUpdate={handleBookingUpdate}
              />
            ))
          }
          {upcomingBookings && upcomingBookings !== "isLoading" && upcomingBookings.length === 0 && <div>No bookings available</div>}
        </div>
        <div className="flex flex-row items-center justify-center gap-[20px] mt-4">
          <Button
            className="bg-darkorange"
            variant="contained"
            disabled={currentPageUpcoming === 1}
            onClick={() => handlePageChange(currentPageUpcoming - 1)}
          >
            Previous
          </Button>
          <Typography variant="body1">
            Page {currentPageUpcoming} of {totalPagesUpcoming}
          </Typography>
          <Button
            className="bg-darkorange"
            variant="contained"
            disabled={currentPageUpcoming === totalPagesUpcoming}
            onClick={() => handlePageChange(currentPageUpcoming + 1)}
          >
            Next
          </Button>
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-2 px-2 box-border gap-[24px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border h-[calc(100vh-400px)] overflow-y-auto">
          {pendingBookings === "isLoading" && <div><Loader /></div>}
          {pendingBookings && pendingBookings !== "isLoading" && pendingBookings.length !== 0 && pendingBookings.result.length !== 0 &&
            pendingBookings.result.map((booking) => (
              <AppointmentCardPendingConsumer
                key={booking.id}
                booking={booking}
                onBookingUpdate={handleBookingUpdate}
              />
            ))
          }
          {pendingBookings && pendingBookings !== "isLoading" && pendingBookings.length === 0 && <div>No bookings available</div>}
        </div>
        <div className="flex flex-row items-center justify-center gap-[20px] mt-4">
          <Button
            className="bg-darkorange"
            variant="contained"
            disabled={currentPagePending === 1}
            onClick={() => handlePageChange(currentPagePending - 1)}
          >
            Previous
          </Button>
          <Typography variant="body1">
            Page {currentPagePending} of {totalPagesPending}
          </Typography>
          <Button
            className="bg-darkorange"
            variant="contained"
            disabled={currentPagePending === totalPagesPending}
            onClick={() => handlePageChange(currentPagePending + 1)}
          >
            Next
          </Button>
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start py-2 px-2 box-border gap-[24px] max-w-full text-neutral-9 mq1050:pr-[126px] mq1050:box-border mq750:pr-[63px] mq750:box-border h-[calc(100vh-400px)] overflow-y-auto">
          {historyBookings === "isLoading" && <div><Loader /></div>}
          {historyBookings && historyBookings !== "isLoading" && historyBookings.length !== 0 && historyBookings.result.length !== 0 &&
            historyBookings.result.map((booking) => (
              <AppointmentCardHistoryConsumer
                key={booking.id}
                booking={booking}
              />
            ))
          }
          {historyBookings && historyBookings !== "isLoading" && historyBookings.length === 0 && <div>No bookings available</div>}
       
        </div>  
         <div className="flex flex-row items-center justify-center gap-[20px] mt-4">
            <Button
              className="bg-darkorange"
              variant="contained"
              disabled={currentPageHistory === 1}
              onClick={() => handlePageChange(currentPageHistory - 1)}
            >
              Previous
            </Button>
            <Typography variant="body1">
              Page {currentPageHistory} of {totalPagesHistory}
            </Typography>
            <Button
              className="bg-darkorange"
              variant="contained"
              disabled={currentPageHistory === totalPagesHistory}
              onClick={() => handlePageChange(currentPageHistory + 1)}
            >
              Next
            </Button>
          </div>
      </TabPanel>
    </div>
  );
};

export default ConsumerSchedule;
