import { useMemo } from "react";

const ServiceCard = ({ propDisplay, propMinWidth, data }) => {
  const textStyle = useMemo(() => {
    return {
      display: propDisplay,
      minWidth: propMinWidth,
    };
  }, [propDisplay, propMinWidth]);

  return (
    <div className="self-stretch shadow-[-1px_0px_0px_#d9d9d9_inset,_0px_1px_0px_#d9d9d9_inset,_0px_-1px_0px_#d9d9d9_inset] flex flex-row items-start justify-start max-w-full [row-gap:20px] mq1050:flex-wrap rounded-xl">
    <img
      className="min-h-[250px] max-h-[250px] min-w-[312px] max-w-[312px] relative rounded-tl-xl rounded-tr-none rounded-br-none rounded-bl-xl object-cover min-h-[250px] mq1050:flex-1"
      loading="lazy"
      alt=""
      src={
        data.userImages && data.userImages.profileImage
          ? data.userImages.profileImage
          : "/Placeholder-profile.png"
      }
    />
    <div className="flex-1 rounded-xl bg-common-white-main flex flex-col items-start justify-start py-4 px-6 box-border gap-[8px] min-w-[411px] max-w-full mq750:min-w-full">
      <div className="self-stretch flex flex-row items-center justify-between gap-[20px] mq450:flex-wrap">
        <div className="w-[295.5px] flex flex-col items-start justify-start py-0 pr-5 pl-0 box-border gap-[8px]">
          <h2 className="m-0 relative text-inherit leading-[36px] font-bold font-inherit inline-block min-w-[56px] mq450:text-lgi mq450:leading-[29px]">
            {data.serviceName}
          </h2>
          <div className="relative text-base leading-[24px] font-semibold text-neutral-8 inline-block min-w-[119px]">
            {data.practitionerFirstName + " " + data.practitionerLastName}
          </div>
        </div>
        <b className="relative text-13xl leading-[48px] inline-block min-w-[62px] whitespace-nowrap mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
          {data.cost ? "$" + data.cost : " "}
        </b>
      </div>
      <div className="self-stretch relative min-w-[500px] max-w-[500px] text-base leading-[24px] text-neutral-8 [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical]">
        {data.serviceDescription ? data.serviceDescription : " "}
      </div>
      <div className="self-stretch flex flex-row items-center justify-center py-4 px-0 gap-[16px] text-center text-sm text-neutral-10 mq750:flex-wrap">
        <div className="flex-1 flex flex-col items-start justify-start py-0 box-border gap-[8px] min-w-[185px]">
          <div className="relative leading-[22px] font-semibold inline-block min-w-[44px]">
            Session Type
          </div>
          <div className="w-32 flex justify-between">
            <img className="w-20 " alt="" src={data.type === 0 || data.type === 2 ? "online_active.png" : "online_inactive.png"} />
            <img className="w-20 pl-[6px]" alt="" src={data.type === 1 || data.type === 2 ? "offline_active.png" : "onsite_inactive.png"} />
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default ServiceCard;
