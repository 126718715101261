import React from 'react';
import Button from '@mui/material/Button';

const OtfBox = ({ status, bookingType, time, title, isConsumer, onAccept, onReject }) => {
  return (
    <div className="w-[343px] h-[150px] p-[16px] bg-[#EAF2FF] rounded-[16px] flex flex-col justify-center items-center">
      
      {/* Title */}
      <div className="text-lg font-semibold text-center">
        {title}
      </div>

      {/* Time and Booking Type */}
      <div className="text-sm text-center">
        {new Date(time).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })} -
        {new Date(time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true })} -
        {bookingType}
      </div>

      {/* Consumer View - Accept/Reject Buttons */}
      {isConsumer ? (
        <div className="flex gap-4 mt-4">
          <Button
            style={{
              flex: 1,
              position: 'relative',
              lineHeight: '20px',
              fontWeight: '500',
              color: 'green',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              cursor: 'pointer',
            }}
            variant="text"
            size="small"
            onClick={onAccept}
        
          >
            Accept
          </Button>
          <Button
            style={{
              flex: 1,
              position: 'relative',
              lineHeight: '20px',
              fontWeight: '500',
              color: 'red',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              cursor: 'pointer',
            }}
            variant="text"
            size="small"
            onClick={onReject}
            disabled={status !== 'pending'} // Disable button if status is not pending
          >
            Reject
          </Button>
        </div>
      ) : (
        // Practitioner View - Show Status
        <div className={`text-xs mt-4 ${getStatusStyle(status)}`}>
          {getStatusText(status)}
        </div>
      )}
    </div>
  );
};

// Helper functions to get status text and styles
const getStatusText = (status) => {
  switch (status) {
    case 'pending':
      return 'Booking invite has been sent, Pending';
    case 'accepted':
      return 'Booking invite has been sent,Accepted';
    case 'rejected':
      return 'Booking invite has been sent,Rejected';
    default:
      return 'Unknown Status';
  }
};

const getStatusStyle = (status) => {
  switch (status) {
    case 'pending':
      return 'text-blue-500';
    case 'accepted':
      return 'text-green-500';
    case 'rejected':
      return 'text-red-500';
    default:
      return 'text-gray-500';
  }
};

export default OtfBox;
