import axios from "axios";

import { BASE_URL } from "../Constants";
import authHeader from './AuthHeader';

import { interceptor } from '../services/Interceptor';
const API_URL = BASE_URL;

class GetPractitionerBooking {
 

  async getPractitionerUpcomingBooking(practitionerId, currentPageUpcoming, fromDate, toDate, selectedUpcomingServices) {
    interceptor();
  
    const timeZoneOffset = new Date().getTimezoneOffset() / 60 * -1;
  
    const baseParams = {
      Id: practitionerId,
      pageNumber: currentPageUpcoming,
      fromDate: fromDate || undefined, 
      toDate: toDate || undefined
    };
  
   
    const filteredParams = Object.fromEntries(
      Object.entries(baseParams).filter(([key, value]) => value != null && value !== '')
    );
  
    const queryString = new URLSearchParams(filteredParams).toString();
  
    const serviceIdsParams = selectedUpcomingServices
      .map(serviceId => `serviceIds=${encodeURIComponent(serviceId)}`)
      .join('&');
    
  
    const url = `${API_URL}PractitionerSchedule/GetPractitionerUpcomingBooking?${queryString}&${serviceIdsParams}`;
  
    return axios.get(url, {
      headers: {
        ...authHeader(),
        TimeZoneOffSet: timeZoneOffset,
      },
    });
  }
  

  async  getPractitionerHistoryBooking(practitionerId, currentPageHistory, fromDate, toDate, bookingStatus, selectedHistoryServices) {
    interceptor();
    
    const timeZoneOffset = new Date().getTimezoneOffset() / 60 * -1;
  
   
    const baseParams = {
      Id: practitionerId,
      pageNumber: currentPageHistory,
      fromDate: fromDate || undefined,       toDate: toDate || undefined,
      bookingStatus: bookingStatus || undefined
    };
  
   
    const filteredParams = Object.fromEntries(
      Object.entries(baseParams).filter(([key, value]) => value != null && value !== '')
    );
  
 
    const queryString = new URLSearchParams(filteredParams).toString();
  
  
    const serviceIdsParams = selectedHistoryServices
      .map(serviceId => `serviceIds=${encodeURIComponent(serviceId)}`)
      .join('&');
    
   
    const url = `${API_URL}PractitionerSchedule/GetPractitionerHistoryBooking?${queryString}&${serviceIdsParams}`;
  
    return axios.get(url, {
      headers: {
        ...authHeader(),
        TimeZoneOffSet: timeZoneOffset,
      },
    });
  }
  


  async  getPractitionerPendingBookings(practitionerId, currentPagePending, fromDate, toDate, selectedPendingServices, timeDuration, bookingType) {
    interceptor();
    
    const timeZoneOffset = new Date().getTimezoneOffset() / 60 * -1;
  
  
    const baseParams = {
      Id: practitionerId,
      pageNumber: currentPagePending,
      fromDate: fromDate || undefined, 
      toDate: toDate || undefined,
      bookingType: bookingType || undefined,
      timeDuration: timeDuration || undefined
    };
  
   
    const filteredParams = Object.fromEntries(
      Object.entries(baseParams).filter(([key, value]) => value != null && value !== '')
    );
  
   
    const queryString = new URLSearchParams(filteredParams).toString();
  
  
    const serviceIdsParams = selectedPendingServices
      .map(serviceId => `serviceIds=${encodeURIComponent(serviceId)}`)
      .join('&');
    
  
    const url = `${API_URL}PractitionerSchedule/GetPractitionerBookings?${queryString}&${serviceIdsParams}`;
  
    return axios.get(url, {
      headers: {
        ...authHeader(),
        TimeZoneOffSet: timeZoneOffset,
      },
    });
  }
  

}

export default new GetPractitionerBooking();

