import { useMemo } from "react";

const ButtonBusinessCard = ({ propDisplay, propMinWidth, data }) => {
  const textStyle = useMemo(() => {
    return {
      display: propDisplay,
      minWidth: propMinWidth,
    };
  }, [propDisplay, propMinWidth]);

  return (
    <div className="max-w-[300px] min-w-[300px] h-[304px] shadow-[0px_1px_2px_rgba(0,_0,_0,_0.3),_0px_1px_3px_1px_rgba(0,_0,_0,_0.15)] rounded-xl bg-neutral-1 shrink-0 flex flex-col items-start justify-start text-left text-xl text-neutral-10 font-web-primary-h3-primary-dm-sans">
      <div className="w-full flex flex-col items-center justify-start">
        <div className="w-full flex flex-row items-center justify-start p-4">
          <div className="flex-1 flex flex-row items-center justify-start gap-[16px] overflow-hidden">
            <img
              className="min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] object-cover rounded-full"
              loading="lazy"
              alt=""
              src={data.userImages && data.userImages.profileImage ? data.userImages.profileImage : "/Placeholder-images.png"}
            />
            <div className="flex-1 flex flex-col items-start justify-start overflow-hidden">
              <b className="w-full truncate leading-[30px] mq450:text-base mq450:leading-[24px]">
                {data.firstName ? data.firstName + " " + data.lastName : data.practitionerFirstName + " " + data.practitionerLastName}
              </b>
              <div className="w-full truncate text-sm leading-[22px] text-neutral-7 overflow-hidden text-ellipsis whitespace-nowrap">
          {data.bio ? `I am a ${data.bio}` : ""}
</div>

            </div>
          </div>
        </div>
        <div className="w-full h-[220px] flex items-center justify-start overflow-hidden rounded-b-xl">
          <img
            className="min-w-full min-h-full object-cover"
            loading="lazy"
            alt=""
            src={data.userImages && data.userImages.images ? data.userImages.images.split('|')[0] : "/Placeholder-profile.png"}
          />
        </div>

      </div>
    </div>
  );
};

export default ButtonBusinessCard;